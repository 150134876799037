import React from 'react'

import { ErrorCharts } from 'components/ErrorCharts/ErrorCharts'

type DashboardChartProps = {
  id: string
  visible?: boolean
}

function DashboardChart({
  id,
  visible
}: DashboardChartProps): React.ReactElement {
  return (
    <React.Fragment>
      {visible ? (
        <canvas id={id} />
      ) : (
        <div className='center'>
          <ErrorCharts />
        </div>
      )}
    </React.Fragment>
  )
}

export default DashboardChart

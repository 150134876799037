import React, { useState } from 'react'

import { KeyboardArrowDown } from '@mui/icons-material'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { Box, Avatar as MUIAvatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-icon.svg'
import bneIcon from 'assets/images/icons/bne.png'
import { ReactComponent as CandidatesIcon } from 'assets/images/icons/candidates.svg'
import { ReactComponent as OverviewIcon } from 'assets/images/icons/home.svg'
import { ReactComponent as JobsIcon } from 'assets/images/icons/jobs.svg'
import { EmailButton } from 'components/EmailButton/EmailButton'
import { ChangeEnvironmentModal } from 'components/Modals/lugarh/ChangeEnvironment/ChangeEnvironmentModal'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import { ADMIN_PERMISSIONS } from 'contants'
import i18n from 'i18n'
import useStore from 'store'
import { AsideMenuActions } from 'store/asideMenu'

import './AsideMenu.scss'
import { Avatar } from '../Avatar/Avatar'

import { LugarhAdmIcon } from 'components/Icons/lugarh/LugarhAdmIcon'

type Props = {
  open: boolean
  onChange: (value: boolean) => void
  logoff: () => void
  fullName: string
  role: string
  returnIconButton: string | null
}

export const AsideMenu: React.FC<Props> = ({
  open,
  onChange,
  fullName,
  role,
  logoff
}) => {
  const { t } = useTranslation('headerSettings')
  const { selectors, dispatch } = useStore()
  const user = selectors.auth.user()
  const location = useLocation()
  const expandedMenu = selectors.asideMenu.isExpanded()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [hasPermission, setHasPermission] = useState<boolean>(true)
  const firstLetter = user?.currentWorkspace.name.charAt(0)
  const setPermission = (permission: boolean) => {
    setHasPermission(permission)
  }

  const callbackUrl =
    window.localStorage.getItem('partnerUrlCallback') &&
    window.localStorage.getItem('partnerUrlCallback') !== null
      ? window.localStorage.getItem('partnerUrlCallback')
      : null

  const expandMenu = () => {
    if (expandedMenu) {
      dispatch(AsideMenuActions.setExpanded(false))
      window.localStorage.setItem('asideMenuExpanded', 'false')
    } else {
      dispatch(AsideMenuActions.setExpanded(true))
      window.localStorage.setItem('asideMenuExpanded', 'true')
    }
  }

  const handleCloseSidebar = () => {
    if (openModal) return
    dispatch(AsideMenuActions.setExpanded(false))
    window.localStorage.setItem('asideMenuExpanded', 'false')
  }

  return (
    <>
      <nav
        className={`navigation-widget navigation-widget-desktop sidebar left delayed ${
          expandedMenu ? 'active' : 'closed'
        }`}
        style={
          !user?.themeCustomization?.secondaryColor
            ? { backgroundColor: user?.themeCustomization?.secondaryColor }
            : { backgroundColor: '#fff' }
        }
        onMouseLeave={handleCloseSidebar}
      >
        <ul className={`menu ${expandedMenu ? '' : 'small'}`}>
          <li>
            <div
              className={`expand-button ${
                expandedMenu ? 'expand-button-right' : ''
              }`}
              onClick={() => expandMenu()}
            >
              {expandedMenu ? (
                <ArrowBackIosRoundedIcon />
              ) : (
                <ArrowForwardIosRoundedIcon />
              )}
            </div>
          </li>
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : 'Workspace'}
            // className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
            <span
              className={`menu-item-link area ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
            >
              {expandedMenu ? 'Workspaces' : ''}
              {!expandedMenu && (
                <MUIAvatar
                  alt={firstLetter}
                  sizes='44px'
                  className='menu-item-link__workspace'
                >
                  {firstLetter}
                </MUIAvatar>
              )}
            </span>
            {expandedMenu ? (
              <Box
                className='navigation-workspace'
                onClick={() => setOpenModal(true)}
              >
                <Box className='navigation-workspace__container'>
                  <p className='navigation-workspace__title default-font'>
                    {user?.firstname} {user?.lastname}
                  </p>
                  <p className='navigation-workspace__subtitle default-font'>
                    {user?.currentWorkspace.name}
                  </p>
                </Box>
                <KeyboardArrowDown />
              </Box>
            ) : null}
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.jobs')}
            className={`${location.pathname === '/jobs' ? 'active' : ''}`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/jobs'
              onClick={() => {
                onChange(false)
              }}
            >
              <JobsIcon className='link-icon' />
              {expandedMenu ? t('sections.jobs') : ''}
            </Link>
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.candidates')}
            className={`${
              location.pathname === '/candidates-database' ? 'active' : ''
            }`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/candidates-database'
              onClick={() => {
                onChange(false)
              }}
            >
              <PeopleAltIcon className='link-icon' />
              {expandedMenu ? t('sections.candidates') : ''}
            </Link>
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.dashboard')}
            className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/dashboard'
              onClick={() => onChange(false)}
            >
              <InsertChartIcon className='link-icon' />
              {expandedMenu ? t('sections.dashboard') : ''}
            </Link>
          </li>
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.settings')}
            className={`${location.pathname === '/settings' ? 'active' : ''}`}
          >
            <Link
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              to='/settings'
              onClick={() => onChange(false)}
            >
              <SettingsIcon className='link-icon' />
              {expandedMenu ? t('sections.settings') : ''}
            </Link>
          </li>
          {/* <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.settings')}
            className={`${location.pathname === '/settings' ? 'active' : ''}`}
          >
            <PermissionAgent
              module='candidate'
              actions={['view']}
              onFail='hidden'
              Component={({ hidden }) =>
                hidden ? null : (
                  <Link
                    className={`menu-item-link ${
                      expandedMenu ? '' : 'text-tooltip-tfr'
                    }`}
                    to='/settings'
                    onClick={() => onChange(false)}
                  >
                    <SettingsIcon className='link-icon' />
                    {expandedMenu ? t('sections.settings') : ''}
                  </Link>
                )
              }
            />
          </li> */}
          <li
            id='navigation-widget-mobile'
            data-tooltip={expandedMenu ? null : t('sections.admission')}
          >
            <a
              className={`menu-item-link ${
                expandedMenu ? '' : 'text-tooltip-tfr'
              }`}
              href={'https://empresa.lugarh.com.br/'}
              target='_blank'
              rel='noreferrer'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: expandedMenu ? 'space-between' : 'center'
              }}
            >
              <LugarhAdmIcon />
              {expandedMenu ? t('sections.admission') : null}
              {expandedMenu ? (
                <span
                  style={{
                    width: '38.67px',
                    height: '15.56px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '2.28px 6.83px 2.28px 6.83px',
                    borderRadius: '13.67px',
                    backgroundColor: '#FDD835',
                    fontSize: '9px',
                    color: `#000000DE`,
                    marginRight: '10px'
                  }}
                >
                  {t('sections.newFlag')}
                </span>
              ) : null}
            </a>
          </li>
          {callbackUrl !== null && callbackUrl !== '' ? (
            <li id='navigation-widget-mobile' data-tooltip={t('jobPostings')}>
              <a
                className={`menu-item-link ${
                  expandedMenu ? '' : 'text-tooltip-tfr'
                }`}
                href={callbackUrl !== null ? callbackUrl : ''}
              >
                <img
                  style={{ width: '30.86px', height: '12.31px' }}
                  src={bneIcon !== null ? bneIcon : ''}
                  className='link-icon'
                />
                {expandedMenu ? t('jobPostings') : ''}
              </a>
            </li>
          ) : (
            ''
          )}
        </ul>
      </nav>

      <nav
        id='navigation-widget-mobile'
        className={`navigation-widget navigation-widget-mobile sidebar left delayed ${
          open ? '' : 'hidden'
        }`}
      >
        <div
          className='navigation-widget-close-button'
          onClick={() => onChange(false)}
        >
          <div className='navigation-widget-close-button-icon icon-back-arrow'>
            <ArrowIcon />
          </div>
        </div>

        <div className='navigation-widget-info-wrap'>
          <div className='navigation-widget-info'>
            <Avatar user={fullName} />

            <p className='navigation-widget-info-title'>
              <Link to='/my-profile'>{fullName}</Link>
            </p>

            <p className='navigation-widget-info-text'>{role}</p>
          </div>
        </div>

        <ul>
          <li
            id='navigation-widget-mobile'
            data-tooltip='Dashboard'
            className={`${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
            <Link to='/dashboard' onClick={() => onChange(false)}>
              <OverviewIcon className='link-icon' />
              {t('sections.dashboard')}
            </Link>
          </li>

          <li
            id='navigation-widget-mobile'
            className={`${location.pathname === '/jobs' ? 'active' : ''}`}
            data-tooltip='Jobs'
          >
            {hasPermission ? (
              <PermissionAgent
                module='job'
                actions={['view']}
                onFail='hidden'
                Component={({ hidden }) =>
                  hidden ? null : (
                    <Link
                      to='/jobs'
                      onClick={() => {
                        onChange(false)
                      }}
                    >
                      <JobsIcon className='link-icon' />
                      {t('sections.jobs')}
                    </Link>
                  )
                }
              />
            ) : (
              <Link to={null} onClick={() => null}>
                <JobsIcon className='link-icon' />
                {t('sections.jobs')}
              </Link>
            )}
          </li>

          <li
            id='navigation-widget-mobile'
            data-tooltip='Candidates'
            className={`${
              location.pathname === '/candidates-database' ? 'active' : ''
            }`}
          >
            {hasPermission ? (
              <PermissionAgent
                module='candidate'
                actions={['view']}
                setPermission={setPermission}
                onFail='hidden'
                Component={({ hidden }) =>
                  hidden ? null : (
                    <Link
                      to='/candidates-database'
                      onClick={() => onChange(false)}
                    >
                      <CandidatesIcon className='link-icon' />
                      {t('sections.candidates')}
                    </Link>
                  )
                }
              />
            ) : (
              <Link to={null} onClick={() => null}>
                <CandidatesIcon className='link-icon' />
                {t('sections.candidates')}
              </Link>
            )}
          </li>
        </ul>

        <p className='navigation-widget-section-title'>{t('myProfile')}</p>
        <Link
          className='navigation-widget-section-link'
          to='/my-profile'
          onClick={() => onChange(false)}
        >
          {t('profileEdit')}
        </Link>
        <p className='navigation-widget-section-title'>{t('company')}</p>
        <Link
          className='navigation-widget-section-link'
          to='/company-settings'
          onClick={() => onChange(false)}
        >
          {t('companySettings')}
        </Link>
        <Link
          className='navigation-widget-section-link'
          to='/hiring-workflow'
          onClick={() => onChange(false)}
        >
          {t('changeCompany')}
        </Link>
        <Link
          className='navigation-widget-section-link'
          to=''
          onClick={() => setOpenModal(true)}
        >
          {t('changeWorkspace')}
        </Link>
        <Link
          className='navigation-widget-section-link'
          to='/hiring-workflow'
          onClick={() => onChange(false)}
        >
          {t('hiringWorkflows')}
        </Link>

        <PermissionAgent
          module='user'
          actions={ADMIN_PERMISSIONS}
          onFail='hidden'
          Component={({ hidden }) =>
            hidden ? null : (
              <Link
                className='navigation-widget-section-link'
                to='/team-management'
                onClick={() => onChange(false)}
              >
                {t('manageTeam')}
              </Link>
            )
          }
        />
        <ChangeEnvironmentModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          customClass='ws-modal'
          variant='workspace'
        />
        <p className='navigation-widget-section-title'>{t('session')}</p>
        <a className='navigation-widget-section-link' onClick={logoff}>
          {t('leave')}
        </a>
        <p className='navigation-widget-section-title'>{t('language')}</p>

        <a
          className='navigation-widget-section-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('en-US')
            onChange(false)
          }}
        >
          {` ${t('english')}`}
        </a>
        <a
          className='navigation-widget-section-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('pt-BR')
            onChange(false)
          }}
        >
          {` ${t('portuguese')}`}
        </a>
        <a
          className='navigation-widget-section-link'
          href='#'
          onClick={() => {
            i18n.changeLanguage('es')
            onChange(false)
          }}
        >
          {` ${t('spanish')}`}
        </a>
      </nav>

      <div
        className={`overlay ${open ? 'open' : ''}`}
        onClick={() => onChange(false)}
      />

      <PermissionAgent
        module='app'
        actions={['help-button-email']}
        onFail='hidden'
        Component={({ hidden }) =>
          hidden ? null : (
            <EmailButton
              helpButtonSubject={t('helpButtonSubject')}
              helpButtonDescription={t('helpButtonDescription')}
            />
          )
        }
      />
    </>
  )
}

/* eslint-disable indent */
import React, { useState, useEffect } from 'react'

import { Error } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import PeopleIcon from '@mui/icons-material/People'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams, useNavigate } from 'react-router-dom'

import {
  ThemeButton,
  ThemeButtonTemplate
} from 'components/Button/lugarh/ThemeButton'
import {
  CandidateCard,
  CandidateCardRef
} from 'components/CandidateCard/CandidateCard'
import { CandidateList } from 'components/CandidateList/CandidateList'
import { CandidatePreview } from 'components/CandidatePreview/CandidatePreview'
import { Loading } from 'components/Loading/Loading'
import { FilterForm } from 'containers/CandidateFilter/CandidateFilterContainer'
import { useStoreNotification } from 'hooks/useStoreNotification'
import { jobs as jobsAPI } from 'services/api'

import './CandidatesListContainer.scss'
import ModalAddDocument from './ModalAddDocument'
import { timestampToDate } from '../../utils/date'

import { useTheme } from 'context/ThemeContext/ThemeContext'
import { NoResultStructure } from 'layouts/NoResult/lugarh/NoResultStructure.layout'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'
import { ChangeEnvironmentModal } from 'components/Modals/lugarh/ChangeEnvironment/ChangeEnvironmentModal'

type Props = {
  viewType: 'grid' | 'list'
  selectedStage: string
  orderBy: string
  moveStepId: string
  clearMoveStepId: () => void
  filter: FilterForm
  stages: Array<WorkflowStageSchema>
  getRefs: (value: CandidateCardRef) => void
  updateStagesCount?: () => void
  profiles: Array<any>
  updateJobs: () => void
}

export const CandidatesListContainer: React.FC<Props> = ({
  viewType,
  selectedStage,
  orderBy,
  moveStepId,
  clearMoveStepId,
  filter,
  stages,
  getRefs,
  updateStagesCount,
  profiles,
  updateJobs
}) => {
  const navigate = useNavigate()
  const { uuid } = useParams<{ uuid: string }>()
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDataV2>()
  const [candidatePreviewModal, setCandidatePreviewModal] = useState(false)
  const [pageData, setPageData] = useState({ page: 1, per_page: 10 })
  const [hasMore, setHasMore] = useState(true)
  const [updateList, setUpdateList] = useState(false)
  const [loadingApi, setLoadingApi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [applications, setApplications] = useState<
    Record<string, JobApplication>
  >({})
  const [openModalAddDocument, setOpenModalAddDocument] = useState(false)
  const [withProfile, setWithProfile] = useState<any>({})
  const { t } = useTranslation('candidatesListContainer')
  const { t: ts } = useTranslation('success')
  const { showNotification } = useStoreNotification()

  const fetchApplications = async () => {
    try {
      const [error, data] = await jobsAPI.listApplications({
        ...pageData,
        hiring_workflow_stage: selectedStage || undefined,
        job_uuid: uuid,
        order_by: orderBy,
        geo_location: filter?.general?.location,
        min_age: filter?.general?.age.min,
        max_age: filter?.general?.age.max,
        sex: filter?.general?.gender,
        marital_status: filter?.general?.civilStatus,
        salary_expectation_min: filter?.general?.salary.min,
        salary_expectation_max: filter?.general?.salary.max,
        city_relocation:
          filter?.general?.availableToMove === 'true'
            ? true
            : filter?.general?.availableToMove === 'false'
              ? false
              : undefined,
        educational_institution: filter?.education?.name,
        educational_course: filter?.education?.course,
        educational_level: filter?.education?.level,
        professional_experience_company: filter?.experience?.company,
        professional_experience_role: filter?.experience?.position,
        professional_experience_years:
          filter?.experience?.timeInYears?.toString(),
        language: filter?.language?.name,
        language_fluency: filter?.language?.level
      })

      if (error) throw new Error(error.toString())
      const newMap = {}
      data?.forEach(application => {
        newMap[application.uuid] = application
      })

      setApplications({ ...applications, ...newMap })
      setHasMore(data?.length === pageData.per_page)
      setPageData({
        page: pageData.page + 1,
        per_page: pageData.per_page
      })
      setUpdateList(false)
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const updateCandidateStage = async (
    application: JobApplication,
    stageId: string
  ) => {
    setLoadingApi(true)
    try {
      await jobsAPI.updateCandidateAplication({
        job_uuid: uuid ?? '',
        aplication_uuid: application.uuid,
        hiring_workflow_stage: stageId
      })
      showNotification({ message: ts('candidateMoved'), type: 'success' })
      setApplications({})
      setHasMore(true)
      setPageData({ page: 1, per_page: 10 })
    } catch (error: unknown) {
      const errorMessage = (error as Error).message
      showNotification({ message: errorMessage, type: 'error' })
    } finally {
      setLoadingApi(false)
      updateJobs()
    }
  }

  const updateCandidateStageWithProfile = async (profileParams?: any) => {
    setLoadingApi(true)
    const { application, stageId } = withProfile
    const { profile } = profileParams
    const hasSelected =
      Object.values(applications).filter(application => application.selected)
        .length >= 1
    try {
      if (application === undefined && hasSelected) {
        Object.values(applications).map(async application => {
          if (application.selected) {
            const params = {
              job_uuid: uuid,
              aplication_uuid: application.uuid,
              profile_uuid: profile,
              hiring_workflow_stage: stageId
            }
            const [error] =
              await jobsAPI.updateCandidateApplicationWithProfile(params)
            setLoadingApi(false)
            if (error) throw new Error(error.toString())
            showNotification({ message: ts('candidateMoved'), type: 'success' })
            setOpenModalAddDocument(false)
            setApplications({})
            setHasMore(true)
            setPageData({ page: 1, per_page: 10 })
          }
          return setUpdateList(true)
        })
        clearMoveStepId()
        return
      }
      const params = {
        job_uuid: uuid,
        aplication_uuid: application.uuid,
        profile_uuid: profile,
        hiring_workflow_stage: stageId
      }
      const [error] =
        await jobsAPI.updateCandidateApplicationWithProfile(params)
      setLoadingApi(false)
      if (error) throw new Error(error.toString())
      showNotification({ message: ts('candidateMoved'), type: 'success' })
      setOpenModalAddDocument(false)
      setApplications({})
      setHasMore(true)
      setPageData({ page: 1, per_page: 10 })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    } finally {
      updateJobs()
    }
  }

  useEffect(() => {
    if (uuid) {
      setHasMore(true)
      setPageData({ page: 1, per_page: 10 })
      setApplications({})
    }
  }, [orderBy, filter, selectedStage])

  useEffect(() => {
    if (pageData.page === 1) fetchApplications()
  }, [pageData])

  useEffect(() => {
    if (moveStepId) {
      const step = stages.find(stage => stage.uuid === moveStepId)
      if (step?.name === 'Solicitar documentos') {
        setWithProfile({
          ...withProfile,
          stageId: moveStepId
        })
        setOpenModalAddDocument(true)
        return
      }
      Object.values(applications).map(async application => {
        if (application.selected) {
          await updateCandidateStage(application, moveStepId)
        }
        return setUpdateList(true)
      })
      clearMoveStepId()
    }
  }, [moveStepId])

  useEffect(() => {
    if (updateList) {
      setHasMore(true)
      setPageData({ page: 1, per_page: 10 })
      setApplications({})
    }
  }, [updateList])

  const handleSelected = (id: string, selected: boolean) => {
    const selectedApplication = applications[id]
    if (selectedApplication) {
      setApplications({
        ...applications,
        [id]: {
          ...selectedApplication,
          selected
        }
      })
    }
  }
  const hasCandidatesInOtherStages = stages.some(stage => {
    return stage.uuid !== selectedStage && stage.candidate_count > 0
  })
  const hasCandidatesInSelectedStage = Object.values(applications).length > 0
  return (
    <>
      {/* <CandidatePreview
        user={`${selectedCandidate?.firstname} ${selectedCandidate?.lastname}`}
        about={selectedCandidate?.about}
        open={candidatePreviewModal}
        education={selectedCandidate?.educationalExperiences}
        experience={selectedCandidate?.professionalExperiences}
        languages={selectedCandidate?.languages}
        infos={[
          selectedCandidate?.geoLocation || '',
          selectedCandidate?.sex || '',
          selectedCandidate?.maritalStatus || ''
        ]}
        onClose={setCandidatePreviewModal}
      /> */}
      {openModalAddDocument && (
        <ModalAddDocument
          open={openModalAddDocument}
          onClose={setOpenModalAddDocument}
          isLoading={loadingApi}
          onSubmit={updateCandidateStageWithProfile}
          profiles={profiles}
        />
      )}
      <>{loading && <Loading />}</>
      {/* {viewType === 'grid' && (
        <InfiniteScroll
          dataLength={Object.values(applications).length}
          next={fetchApplications}
          hasMore={hasMore}
          style={{ overflow: 'hidden' }}
          loader={
            <div className='row w-100'>
              <div className='col-12 d-flex justify-content-center'>
                <Loading />
              </div>
            </div>
          }
        >
          {Object.values(applications).length > 0 ? (
            <div className='grid grid-2-2-2-2-2-2'>
              {Object.values(applications).map((application, index) => (
                <CandidateCard
                  key={application.uuid}
                  onSelected={value => handleSelected(application.uuid, value)}
                  selected={application.selected}
                  name={`${application.candidate?.personal_info?.firstname} ${application.candidate?.personal_info?.lastname}`}
                  date={timestampToDate(application.createdAt)}
                  review={application.reviewAvg}
                  stages={stages}
                  onShowPreview={() => {
                    setCandidatePreviewModal(true)
                    setSelectedCandidate(application.candidate)
                  }}
                  onShowDetail={() => {
                    navigate({
                      pathname: `/candidate/${application.candidate.uuid}`,
                      state: {
                        stages,
                        candidate: application.candidate,
                        applicationUUID: application.uuid,
                        jobUUID: application.job.uuid
                      }
                    })
                  }}
                  onMoveToStep={stepId =>
                    updateCandidateStage(application, stepId)
                  }
                  getRefs={refs => index === 0 && getRefs(refs)}
                />
              ))}
            </div>
          ) : (
            !hasMore && (
              <div className='mt-5 w-100 d-flex justify-content-center align-items-center'>
                <h2>{t('notfound')}</h2>
              </div>
            )
          )}
        </InfiniteScroll>
      )} */}
      {viewType === 'list' && (
        <InfiniteScroll
          dataLength={Object.values(applications).length}
          next={fetchApplications}
          hasMore={hasMore}
          style={{ overflow: 'hidden' }}
          loader={
            <div className='row w-100'>
              <div className='col-12 d-flex justify-content-center'>
                <Loading />
              </div>
            </div>
          }
        >
          {hasCandidatesInSelectedStage ? (
            <div className='grid'>
              {Object.values(applications).map(application => (
                <CandidateList
                  key={application.uuid}
                  onSelected={value => handleSelected(application.uuid, value)}
                  selected={application.selected}
                  name={`${application.candidate?.personalInfo?.firstname} ${application.candidate?.personalInfo?.lastname}`}
                  date={timestampToDate(application.createdAt)}
                  review={application.reviewAvg}
                  stages={stages}
                  onShowPreview={() => {
                    setCandidatePreviewModal(true)
                    setSelectedCandidate(application.candidate)
                  }}
                  onShowDetail={() =>
                    navigate(`/candidate/${application.candidate.uuid}`)
                  }
                  onMoveToStep={stepId => {
                    const step = stages.find(stage => stage.uuid === stepId)
                    if (step?.name === 'Solicitar documentos') {
                      setOpenModalAddDocument(true)
                      setWithProfile({
                        ...withProfile,
                        application,
                        stageId: stepId
                      })
                      return
                    }
                    updateCandidateStage(application, stepId)
                  }}
                />
              ))}
            </div>
          ) : (
            <div className='theme-lugarh'>
              {hasCandidatesInOtherStages ? (
                <NoResultStructure
                  title={t('noResults.titleStage')}
                  subtitle={t('noResults.subtitleStage')}
                />
              ) : (
                <NoResultStructure
                  title={t('noResults.title')}
                  subtitle={t('noResults.subtitle')}
                >
                  <ThemeButton
                    template={ThemeButtonTemplate.PRIMARY}
                    title={t('noResults.addJob')}
                    onClick={() => navigate('/add-candidate')}
                    startIcon={<AddIcon />}
                  />
                  <ThemeButton
                    template={ThemeButtonTemplate.OUTLINED}
                    title={t('noResults.changeWorkspace')}
                    customClass='txt-s default-font'
                    startIcon={<PeopleIcon />}
                    onClick={() => navigate(`/candidates-database`)}
                  />
                </NoResultStructure>
              )}
            </div>
          )}
        </InfiniteScroll>
      )}
    </>
  )
}

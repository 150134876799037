import React, { useCallback, useEffect, useState } from 'react'

import { DefaultModal } from 'components/Modals/lugarh/Default/DefaultModal'

import './ChangeEnvironmentModal.style.scss'
import { PermissionAgent } from 'components/PermissionAgent/PermissionAgent'
import SingleSelectableList from 'components/SelectableList/lugarh/SingleSelectableList'
import { useStoreNotification } from 'hooks/useStoreNotification'

import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'

import { RoutePaths } from 'router/Router.config'
import * as wsApi from 'services/api/workspaces/workspaces'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import { Loading } from 'components/Loading/lugarh/Loading'
import {
  ThemeButton,
  ThemeButtonTemplate
} from 'components/Button/lugarh/ThemeButton'

export type ModalType = 'company' | 'workspace'
export interface CreateWorkspaceModalProps {
  open: boolean
  variant?: ModalType
  onClose: () => void
  customClass?: string
}

export const ChangeEnvironmentModal: React.FC<CreateWorkspaceModalProps> = ({
  open,
  onClose,
  customClass = '',
  variant = 'workspace'
}) => {
  const { t } = useTranslation('changeEnvironmentModal')
  const { t: tNotification } = useTranslation('notifications')
  const [data, setData] = useState<WorkspaceSchema[]>()
  const [selected, setSelected] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { dispatch } = useStore()
  const { showNotification } = useStoreNotification()
  const navigate = useNavigate()

  const fetchWorkspaces = async () => {
    setIsLoading(true)
    try {
      const response = await wsApi.list()
      setData(response.data)
    } catch (error: unknown) {
      const errorMessage = (error as Error).message
      showNotification({
        message: tNotification(`lugarh.error.${errorMessage}`),
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleFormSubmit = () => {
    const selectedWorkspace = data?.find(
      workspace => workspace.uuid === selected
    )

    // Save the selected workspace uuid in the local storage
    // as Workspace-Tenant to be added/updated on API requests headers
    selected &&
      selectedWorkspace &&
      window.localStorage.setItem('Workspace-Tenant', selectedWorkspace.uuid)

    // Save the selected workspace in the user's store
    selected &&
      selectedWorkspace &&
      dispatch(
        authActions.setCurrentWorkspace({
          uuid: selected,
          name: selectedWorkspace?.name
        })
      )

    navigate(0)
  }

  const handleSelectChange = useCallback((selected: any) => {
    setSelected(selected)
  }, [])

  const convertData = useCallback(
    (data: WorkspaceSchema[]): Workspace[] =>
      data.map(workspace => ({
        uuid: workspace.uuid,
        name: workspace.name,
        slug: workspace.slug || ''
      })),
    []
  )

  // Só faz o fetch se a modal estiver aberta
  useEffect(() => {
    open && fetchWorkspaces()
  }, [open])

  useEffect(() => {
    if (!open) {
      return () => {
        setSelected(undefined)
      }
    }
  }, [open])

  return (
    <DefaultModal
      open={open}
      onClose={onClose}
      customClass={`ce-modal ${customClass}`}
      modalTitle={t(`lugarh.title`)}
      modalSubtitle={t(`lugarh.subtitle`)}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <DefaultModal.Content customClass='form'>
          <div className='form__step-2'>
            <div className='mui-modal__list ce-modal__list'>
              <SingleSelectableList<Workspace>
                items={convertData(data || [])}
                onSelect={handleSelectChange}
                labels={['name', 'slug', 'uuid']}
                itemIdentifier='uuid'
                selected={selected || ''}
              />
            </div>
          </div>
          <div className='ce-modal__footer'>
            <PermissionAgent
              module='hiring-workflow'
              actions={['view']}
              onFail='hidden'
              Component={({ hidden }) =>
                hidden ? null : (
                  <Link
                    to={'/workspaces'}
                    className='ce-modal__link theme-lugarh__link'
                  >
                    {t(`lugarh.actions.manage`)}
                  </Link>
                )
              }
            />
            <ThemeButton
              title={t(`lugarh.actions.change`)}
              template={ThemeButtonTemplate.PRIMARY_BLUE}
              customClass='mui-modal__button btn__primary form__button'
              onClick={handleFormSubmit}
            />
          </div>
        </DefaultModal.Content>
      )}
    </DefaultModal>
  )
}

import React, { useEffect, useState } from 'react'
import './App.scss'

import { Crisp } from 'crisp-sdk-web'
import i18n from 'i18next'
import CookieConsent from 'react-cookie-consent'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Notifications } from 'components/Notification/Notification'

import './components/Cookies/Cookies.scss'
import Router from 'router/Router'
import api, { changeApiParams } from 'services/api/api'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import * as notificationActions from 'store/notification/actions'
import {
  CheckCallbackUrl,
  RedirectToCallbackUrl
} from 'utils/partnerCallbackUrl'
import userCheckPermission from 'utils/userCheckPermission'

const App: React.FC = () => {
  const { dispatch, selectors } = useStore()
  const [completedSetup, setCompletedSetup] = useState(false)
  const userLogged = selectors.auth.logged()
  const keepConnected = selectors.auth.keepConnected()
  const refreshToken = selectors.auth.refreshToken()
  const user = selectors.auth.user()
  const { t } = useTranslation('errors')
  interface UrlParams {
    token: string
    refresh_token: string
    callback_url: string
    language: string
    associate: string
  }

  const getUrlParams = () => {
    const paramKeys = [
      'token',
      'refresh_token',
      'callback_url',
      'language',
      'associate'
    ]
    const urlObject = new URL(window.location.href)
    const urlParams: UrlParams = {
      token: '',
      refresh_token: '',
      callback_url: '',
      language: '',
      associate: ''
    }

    for (let i = 0; i < paramKeys.length; i++) {
      if (urlObject.searchParams.get(paramKeys[i])) {
        urlParams[paramKeys[i]] = urlObject.searchParams.get(paramKeys[i])
      }
    }
    return urlParams
  }

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  // Para testar as notificações
  // useEffect(() => {
  //   showNotification({
  //     message: 'Testando notificações',
  //     type: 'success'
  //   })
  // }, [])

  useEffect(() => {
    setCompletedSetup(true)
    api.interceptors.response.use(
      response => response,
      error => {
        if (CheckCallbackUrl() && error.response.status === 401) {
          RedirectToCallbackUrl()
        }
        if (error.response.status === 401) {
          dispatch(authActions.logoff())
          sessionStorage.setItem('showLoginNotification', 'true')
          const shouldShowNotification = sessionStorage.getItem(
            'showLoginNotification'
          )
          if (shouldShowNotification) {
            showNotification({
              message: t('expiredtoken'),
              type: 'error'
            })
          }
        }
        const timeoutError = error?.message.includes('timeout')

        if (timeoutError) {
          showNotification({
            message: t('timeoutError'),
            type: 'error'
          })
        }

        const invalidCredentials =
          error.response &&
          error.response.status === 401 &&
          error.response.data.message === 'The credentials are not valid.'

        if (invalidCredentials && userLogged && keepConnected) {
          dispatch(authActions.refreshToken(refreshToken as string))
          return error.response
        }

        if (invalidCredentials && userLogged) {
          showNotification({
            message: t('expiredtoken'),
            type: 'error',
            timeout: 20 * 1000
          })

          dispatch(authActions.logoff())

          return dispatch(authActions.setLoginError('EXPIRED_SESSION'))
        } else if (invalidCredentials) {
          dispatch(authActions.logoff())

          return error.response
        }

        return error.response
      }
    )

    const urlParams = getUrlParams()
    if (
      userLogged === false &&
      urlParams.token &&
      urlParams.refresh_token &&
      urlParams.callback_url
    ) {
      window.localStorage.setItem('partnerUrlCallback', urlParams.callback_url)

      let language = ''
      switch (urlParams.language) {
        case 'en':
          language = 'en-US'
          break
        case 'es':
          language = 'es'
          break
        case 'pt':
          language = 'pt-BR'
          break
      }
      i18n.changeLanguage(language !== '' ? language : 'pt-BR')

      dispatch(
        authActions.directLogin({
          token: urlParams.token,
          refreshToken: urlParams.refresh_token,
          expiresIn: 300,
          refreshExpiresIn: 1800
        })
      )
    }
    if (urlParams.associate) {
      window.localStorage.setItem('associate', urlParams.associate)
    }
  }, [])

  useEffect(() => {
    changeApiParams(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    if (userLogged) {
      Crisp.configure('2604683f-975e-4919-b868-1f3a6d6c8886')
      Crisp.setPosition('right')
    }
  }, [userLogged])

  return (
    <>
      <CookieConsent
        enableDeclineButton
        location='bottom'
        buttonText={t('cookie:buttonAccept')}
        cookieName='myRecrudCookies'
        style={{
          background: '#00033F',
          fontSize: '15px',
          height: '91px',
          justifyContent: 'center',
          padding: '10px',
          gap: '10px',
          zIndex: '2000000'
        }}
        buttonStyle={{
          background: '#127EFF',
          color: 'white',
          borderRadius: '8px',
          fontSize: '14px',
          width: '148px',
          height: '50px',
          padding: '10px',
          gap: '10px'
        }}
        declineButtonStyle={{
          background: '#D12923',
          borderRadius: '8px',
          fontSize: '14px',
          width: '148px',
          height: '50px',
          padding: '10px',
          gap: '10px'
        }}
        declineButtonText={t('cookie:buttonRefuse')}
        expires={150}
      >
        <div className='messageCookie'>{t('cookie:message')}</div>
      </CookieConsent>
      {userLogged ? (
        <Helmet>
          <title>Lugarh | R&S</title>
        </Helmet>
      ) : (
        ''
      )}
      <Notifications />
      {completedSetup && <Router />}
    </>
  )
}

export default App

const ptBR: AppLocale = {
  languages: {
    'pt-BR': 'Português',
    'en-US': 'English'
  },
  defaultText: {
    select: 'Selecione'
  },
  notifications: {
    lugarh: {
      error: {
        'An error occurred while processing your request':
          'Ocorreu um erro ao processar sua solicitação',
        'Not authorized, please check the workspace':
          'Não autorizado. Verifique o workspace.',
        permission: 'Você não tem permissão para acessar este recurso'
      },
      success: {
        'Your request was successfully completed':
          'Sua solicitação foi concluída com sucesso'
      },
      goToLinkLabel: 'IR PARA'
    }
  },
  goHomeButton: {
    lugarh: {
      goHome: 'Ir para a página inicial'
    }
  },
  errors: {
    onlySpaces: 'Não pode conter apenas espaços em branco.',
    minBudget: 'O valor mínimo é de 650',
    errorTitle: 'Erro',
    forbbidenError: 'Você não permissão para acessar este recurso.',
    maxLength: 'São permitidos apenas 2.000 caracteres',
    requiredField: 'Campo obrigatório',
    specialCharacters: 'Somente letras e números são permitidos',
    dateInvalidFormat:
      'Por favor, insira sua data de nascimento no formato correto.',
    deadlineInvalidFormat:
      'Por favor, insira um prazo para encerramento válido.',
    futureDate:
      'A data de nascimento não pode ser no futuro. Por favor, insira uma data válida.',
    invalidBirthdate:
      'A data de nascimento fornecida está fora do intervalo aceitável. Certifique-se de inserir uma data válida.',
    invalidStartDateFormat:
      'Por favor, insira a data de início no formato correto.',
    futureStartDate: 'A data de início não pode ser no futuro.',
    invalidStartDate:
      'A data de início fornecida está fora do intervalo aceitável. Certifique-se de inserir uma data válida.',
    invalidEndDateFormat:
      'Por favor, insira a data de término no formato correto.',
    invalidEndDateOrder:
      'A data de término do curso deve ser posterior à data de início.',
    invalidEndDate:
      'A data de término fornecida está fora do intervalo aceitável. Certifique-se de inserir uma data válida.',
    futureEndDate: 'A data de término do curso não pode ser no futuro.',
    expiredtoken: 'Sua conexão expirou',
    emailInvalid: 'E-mail invalido!',
    fieldInvalid: 'Campo invalido!',
    fieldMinLength: 'Deve ter no mínimo 3 caracteres.',
    onRequestFail: 'Ocorreu um erro ao obter os dados, favor tentar novamente',
    addNoteError: 'Erro ao adicionar nota, favor tentar novamente',
    addReview: 'Erro ao adicionar nota',
    cnpj: 'O formato do CNPJ está incorreto',
    cnpjMaxLength: 'Atingiu o limite máximo de 18 caracteres.',
    cpfCnpjInvalid: 'O número digitado é inválido.',
    cpfCnpjMinLength: 'Deve ter no mínimo 14 caracteres.',
    cpfCnpjMaxLength: 'Atingiu o limite máximo de 18 caracteres.',
    passwordMin: 'A senha precisa ter no minimo 6 caracteres.',
    passwordConfirm: 'As senhas devem ser iguais!',
    finalizeCompanyRegistration:
      'Para acessar este recurso você precisa finalizar o cadastro da empresa.',
    invalidSlug: 'Link da página de carreiras invalido!',
    linkAvailable: 'Link Disponivel!',
    linkUnavailable: 'Link Indisponivel',
    invalidComapanyName: 'Nome da empresa não pode conter caracteres especiais',
    applicationError: 'Este registro já existe em nossos bancos de dados',
    timeoutError: 'O servidor demorou para responder. Tente novamente',
    emptyCity: 'O campo localidade precisa ser informado.',
    limitReached: 'Este recurso já atingiu o limite do plano.',
    creditCardNumberInvalid: 'Número de cartão inválido',
    creditCardCvvInvalid: 'CVV inválido',
    creditCardExpirationMonthInvalid: 'Mês inválido',
    creditCardExpirationYearInvalid: 'Ano inválido',
    hiringWorkflowNameMaxLength: 'Atingiu o limite máximo de 80 caracteres.',
    hiringWorkflowDescriptionMaxLength:
      'Atingiu o limite máximo de 300 caracteres.',
    downloadAttachedFile: 'O arquivo não existe ou não está mais disponível',
    serverError: 'O servidor não conseguiu concluir sua solicitação.'
  },
  success: {
    candidateMoved: 'Candidato movido com sucesso',
    noteAdded: 'Nota adicionada com sucesso',
    addReview: 'Nota adicionada com sucesso',
    applicationSuccess: 'Candidato aplicado com sucesso',
    applicationsSuccess: 'Candidatos aplicados com sucesso',
    jobMoved: 'Vaga movida com sucesso',
    jobDuplicated: 'Vaga duplicada com sucesso'
  },
  resumeTooltip: {
    training: 'Formação:',
    experience: 'Experiência',
    experiences: 'Experiências',
    year: 'ano',
    years: 'anos',
    month: 'mês',
    months: 'meses',
    day: 'dia',
    days: 'dias',
    and: 'e',
    seeCompleteResume: 'VER CURRÍCULO COMPLETO',
    message: {
      education: {
        no_course: 'Curso não informado',
        no_grade: 'Nível de formação não informado'
      },
      professional: {
        no_role: 'Cargo não informado',
        no_time: 'Tempo de experiência não informado'
      }
    }
  },
  cookie: {
    message:
      'Usamos cookies para ajudar a personalizar o conteúdo e fornecer uma experiência mais segura, ' +
      'não armazenamos dados pessoais. Ao navegar pelas páginas, você declara estar de acordo com nossa ' +
      'Política de Privacidade.',
    buttonAccept: 'Aceitar',
    buttonRefuse: 'Recusar'
  },
  common: {
    current: 'Atual',
    gender: {
      male: 'Masculino',
      female: 'Feminino',
      other: 'Outro'
    },
    civilStatus: {
      single: 'Solteiro',
      married: 'Casado',
      divorced: 'Divorciado',
      widowed: 'Viúvo',
      other: 'Outros'
    },
    applyToJob: 'Aplicar para Vaga'
  },
  createWorkflow: {
    create: 'Criar Fluxo de Contratação',
    update: 'Atualizar Fluxo de Contratação'
  },
  home: {
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'A próxima geração de gerenciamento de relacionamento de candidatos.'
    },
    content: 'Edite o arquivo src/Home.tsx e salve para atualizar'
  },
  worktime: {
    year: 'ano',
    years: 'anos',
    month: 'mês',
    months: 'meses',
    day: 'dia',
    days: 'dias',
    and: 'e'
  },
  uploadBox: {
    minSizeRecommended: 'tamanho sugerido 400 x 50 px',
    logo: 'Logo',
    suggestedSize: 'Tamanho sugerido'
  },
  searchBar: {
    allCandidates: 'Ver todos candidatos',
    allJobs: 'Ver todas vagas',
    placeholder: 'Busque por candidatos, vagas e tags',
    results: {
      candidates: 'Candidatos encontrados',
      jobs: 'Vagas encontradas'
    },
    searching: 'Buscando...',
    notFound: 'Nenhum resultado encontrado',
    appliedFor: 'aplicou para {{number}} vagas',
    candidatesNumber: '{{number}} candidatos',
    createdAt: 'Criado em {{date}}',
    candidates: 'candidatos'
  },
  header: {
    about: 'Sobre Nós',
    terms: 'Termos de Uso',
    signup: 'Criar Conta'
  },
  headerSettings: {
    workspaces: 'Workspaces',
    myProfile: 'Meu perfil',
    profileEdit: 'Editar perfil',
    company: 'Empresa',
    subscriptionSettings: 'Configurações da assinatura',
    companySettings: 'Configurações da Empresa',
    jobPostings: 'Gestão de Vagas',
    admission: 'Admissão',
    hiringWorkflows: 'Fluxos de Contratação',
    changeCompany: 'Trocar de empresa',
    changeWorkspace: 'Trocar de workspace',
    manageWorkspace: 'Gerenciar workspace',
    manageTeam: 'Gerenciar Time',
    language: 'Idioma',
    english: 'Inglês',
    portuguese: 'Português',
    spanish: 'Espanhol',
    session: 'Sessão',
    leave: 'Sair',
    hello: 'Olá',
    sections: {
      dashboard: 'Dashboard',
      jobs: 'Vagas',
      candidates: 'Candidatos',
      settings: 'Configurações',
      admission: 'Admissão de candidatos',
      newFlag: 'NOVO'
    },
    helpButtonDescription: 'Ajuda',
    helpButtonSubject: 'Ajuda na Gestão de Candidatos'
  },
  user: {
    role: {
      admin: 'Administrador',
      admin_rocketmat: 'Administrador',
      manager: 'Gerente',
      recruiter: 'Recrutador',
      recruiter_webtactics: 'Recrutador Webtactics'
    },
    indicationButton: 'Indique o Recrud',
    copyLinkSuccessMessage: 'Link copiado com sucesso',
    here: 'Aqui'
  },
  router: {
    pageNotFound: 'Página não encontrada. Por favor, contate o suporte.'
  },
  settings: {
    lugarh: {
      title: 'Configurações',
      subtitle:
        'Aqui você pode controlar as informações relacionadas a sua conta e o processo seletivo da sua empresa.',
      profile: {
        title: 'Perfil',
        subtitle:
          'Edite suas informações pessoais como foto, nome completo, e-mail e senha.'
      },
      team: {
        title: 'Times',
        subtitle:
          'Gerencie os times de recrutamento e seleção da sua instituição.'
      },
      workspaces: {
        title: 'Workspaces',
        subtitle: 'Adicione, edite ou remova os workspaces da sua empresa.'
      },
      hiringWorkflows: {
        title: 'Fluxos de contratação',
        subtitle:
          'Defina os fluxos de contratação que seus candidatos irão enfrentar.'
      }
    }
  },
  login: {
    lugarh: {
      title:
        '[[span]] Revolucione seu processo de RH[[/span]] [[span.highlight]]de ponta a ponta[[/span]] [[span]]em um único Lugarh[[/span]]',
      subtitle:
        'Além de uma poderosa ferramenta de Recrutamento e Seleção, oferecemos um ecossistema completo para',
      itemOne: 'Admissão',
      itemTwo: 'Gestão de pessoas',
      itemThree: 'BPO de coleta de documentos',
      specialist: 'FALE COM UM ESPECIALISTA',
      form: {
        title: 'Entre na sua conta',
        email: {
          placeholder: 'E-mail'
        },
        password: {
          placeholder: 'Senha',
          show: 'Mostrar',
          hide: 'Esconder'
        },
        keepConnected: {
          label: 'Manter conectado'
        },
        submitButton: 'Entrar',
        submitButtonLoading: 'Entrando...',
        errors: {
          emailRequired: 'E-mail é obrigatório',
          emailInvalid: 'E-mail inválido',
          passwordMin: 'Mínimo 6 caracteres',
          passwordRequired: 'Senha é obrigatória'
        }
      },
      forgotPassword: 'Esqueceu a senha?',
      noAccount: 'Ainda não tem uma conta?',
      createAccount: 'Crie agora',
      chooseLanguage: 'Escolha o idioma',
      errors: {
        blockedUser:
          'Usuário sem permissão de acesso. Entre em contato com o administrador para mais informações.',
        expiredSubscription:
          'Assinatura expirada. Entre em contato com o administrador para mais informações.',
        expiredSession: 'Sessão expirada. Faça login novamente.',
        loginError: 'Usuário ou senha inválidos. Tente novamente.'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'A próxima geração de gerenciamento de relacionamento de candidatos'
    },
    welcome: 'Seja bem-vindo à',
    firstParagraph:
      'Uma plataforma de recrutamento simples, fácil e inteligente.',
    secondParagraph: 'Aproveite a experiência!',
    loginTitle: 'Entrar na sua conta',
    firstname: 'Nome',
    lastname: 'Sobrenome',
    email: 'E-mail',
    password: 'Senha',
    loginCheckBox: 'Manter conectado',
    forgotPassword: 'Esqueceu a Senha?',
    loginAccount: 'Entrar na sua Conta!',
    registerTitle: 'Criar sua Conta!',
    repeatPassword: 'Repetir Senha',
    registerCheckBox: 'Eu concordo com os termos de uso!',
    registerNow: 'Cadastrar Agora!',
    aboutUs: 'Sobre Nós',
    privacy: 'Políticas de Privacidade',
    terms: 'Termos',
    whatsRecrud: 'O que é o Recrud?',
    loginError: 'Usuário ou senha inválidos. Tente novamente',
    expiredSession: 'Sessão expirada. Faça login novamente',
    blockedUser:
      'Usuário sem permissão de acesso. Entre em contato com o administrador para mais informações.',
    expiredSubscription:
      'Assinatura expirada. Entre em contato com o administrador para mais informações.',
    signupSuccess: 'Registro efetuado com sucesso!',
    registerError: 'Ocorreu um erro ao registrar, favor tentar novamente',
    registerErrorDataExists:
      'O e-mail já está sendo utilizado, tente novamente',
    register: 'Registrar',
    login: 'Entrar',
    passwordConfirm: 'Senhas não combinam!',
    language: 'Idioma',
    acquisitionChannel: 'Como nos encontrou?'
  },

  card: {
    lugarh: {
      title:
        'Fale com um especialista e conheça os melhores planos para sua empresa',
      subtitle:
        'Para descobrir os planos que melhor atendem às suas necessidades, te convidamos a conversar com nossos especialistas, que estão prontos para te ajudar de maneira personalizada.',
      ctaButton: 'Conhecer os planos',
      backButton: 'Voltar'
    }
  },
  selectableList: {
    lugarh: {
      searchInputPlaceholder: 'Pesquisar pelo nome',
      selectAllLabel: 'Selecionar todos',
      notFound: 'Não foram encontrados resultados para a pesquisa'
    }
  },
  workspaceForm: {
    lugarh: {
      fetchUsersError: 'Erro ao buscar usuários',
      placeholder: {
        name: 'Nome do Workspace',
        slug: 'Apelido do Workspace',
        cnpj: 'CNPJ',
        postcode: 'CEP',
        address_line1: 'Endereço (Rua e Número)',
        address_line2: 'Complemento',
        city: 'Cidade',
        region: 'Estado',
        country: 'País'
      },
      addAddressLabel: 'Adicionar Endereço',
      submitButton: {
        stepOne: 'Continuar',
        stepTwo: 'Enviar',
        loading: 'Processando...'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Nome do workspace deve ter no máximo 80 caracteres',
        'Nome do workspace é obrigatório': 'Nome do workspace é obrigatório',
        'CNPJ deve conter apenas números': 'CNPJ deve conter apenas números',
        'CNPJ deve ter 14 dígitos': 'CNPJ deve ter 14 dígitos',
        'CEP é obrigatório': 'CEP é obrigatório',
        'Endereço é obrigatório': 'Endereço é obrigatório',
        'Cidade é obrigatória': 'Cidade é obrigatória',
        'País é obrigatório': 'País é obrigatório',
        'Adicione pelo menos um usuário': 'Adicione pelo menos um usuário',
        'É obrigatório adicionar usuários': 'É obrigatório adicionar usuários'
      }
    }
  },
  updateWorkspaceForm: {
    lugarh: {
      fetchUsersError: 'Erro ao buscar usuários',
      placeholder: {
        name: 'Nome do Workspace *',
        slug: 'Apelido do Workspace (Opcional)',
        cnpj: 'CNPJ (Opcional)',
        postcode: 'CEP',
        address_line1: 'Endereço (Rua e Número)',
        address_line2: 'Complemento',
        city: 'Cidade',
        region: 'Estado',
        country: 'País'
      },
      addAddressLabel: 'Adicionar endereço no workspace',
      submitButton: {
        stepOne: 'Continuar',
        stepTwo: 'Salvar informações',
        loading: 'Processando...',
        back: 'Voltar'
      },
      errors: {
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Nome do workspace deve ter no máximo 80 caracteres',
        'Nome do workspace é obrigatório': 'Nome do workspace é obrigatório',
        'CNPJ deve conter apenas números': 'CNPJ deve conter apenas números',
        'CNPJ deve ter 14 dígitos': 'CNPJ deve ter 14 dígitos',
        'CEP é obrigatório': 'CEP é obrigatório',
        'Endereço é obrigatório': 'Endereço é obrigatório',
        'Cidade é obrigatória': 'Cidade é obrigatória',
        'País é obrigatório': 'País é obrigatório',
        'Adicione pelo menos um usuário': 'Adicione pelo menos um usuário',
        'É obrigatório adicionar usuários': 'É obrigatório adicionar usuários'
      }
    }
  },
  editUserForm: {
    lugarh: {
      fetchWorkspacesError: 'Erro ao buscar workspaces',
      placeholder: {
        firstname: 'Nome',
        lastname: 'Sobrenome',
        password: 'Senha',
        email: 'E-mail',
        role: {
          select: 'Selecione o cargo',
          admin: 'Administrador',
          manager: 'Gerente',
          recruiter: 'Recrutador'
        }
      },
      submitButton: {
        stepOne: 'Continuar',
        stepTwo: 'Enviar',
        loading: 'Processando...',
        back: 'Voltar'
      },
      errors: {
        'Required field': 'Campo obrigatório',
        'Nome do workspace deve ter no máximo 80 caracteres':
          'Nome do workspace deve ter no máximo 80 caracteres',
        'Nome do workspace é obrigatório': 'Nome do workspace é obrigatório',
        'CNPJ deve conter apenas números': 'CNPJ deve conter apenas números',
        'CNPJ deve ter 14 dígitos': 'CNPJ deve ter 14 dígitos',
        'CEP é obrigatório': 'CEP é obrigatório',
        'Endereço é obrigatório': 'Endereço é obrigatório',
        'Cidade é obrigatória': 'Cidade é obrigatória',
        'País é obrigatório': 'País é obrigatório',
        'Adicione pelo menos um usuário': 'Adicione pelo menos um usuário',
        'É obrigatório adicionar usuários': 'É obrigatório adicionar usuários'
      }
    },
    errors: {
      requiredField: 'Campo obrigatório',
      passwordMin: 'A senha deve ter pelo menos 6 caracteres',
      selectLastOne: 'Selecione pelo menos um workspace',
      workspaceRequired: 'Workspace é um campo obrigatório'
    }
  },
  exportResume: {
    title: 'Currículo',
    description:
      'Selecione as informações do currículo que você deseja que apareçam no currículo e defina em qual formato exportar:',
    cvVersion: {
      edited: 'Editado',
      normal: 'Normal'
    },
    selectButton: {
      selected: 'DESSELECIONAR TODOS',
      unselected: 'SELECIONAR TODOS'
    },
    checkbox: {
      selected: 'Desselecionar todos',
      unselected: 'Selecionar todos'
    },
    resumeType: {
      label: 'Versão do currículo *',
      options: {
        default: 'Selecione uma opção',
        edited: 'Editado',
        normal: 'Original',
        disabled_tooltip:
          'Este candidato não possui currículo cadastrado no BNE.'
      }
    },
    sections: {
      personal_data: {
        title: 'Dados Pessoais',
        fields: {
          full_name: 'Nome completo',
          age: 'Idade',
          children: 'Filhos',
          marital_status: 'Estado civil',
          license: 'Habilitação',
          license_number: 'Nº Habilitação',
          vehicle: 'Veículos',
          linkedin: 'Linkedin',
          yes: 'Sim',
          no: 'Não'
        }
      },
      professional_experience: {
        title: 'Experiência profissional',
        fields: {
          position: 'Cargo',
          enterprise: 'Empresa',
          professional_experience_time: 'Tempo de experiência',
          company_activity: 'Atividade da empresa',
          last_salary: 'Último salário',
          assignments: 'Atribuições',
          include_skills: 'Incluir habilidades',
          branch: 'Ramo:'
        },
        labels: {
          heigth: 'Altura:',
          weight: 'Peso:',
          deficiency: 'Deficiência: '
        }
      },
      address: {
        title: 'Endereço',
        fields: {
          city: 'Cidade',
          neighborhood: 'Bairro',
          postal_code: 'CEP',
          address: 'Endereço',
          complement: 'Complemento'
        }
      },
      disponibility: {
        title: 'Disponibilidades',
        fields: {
          period: 'Período',
          home_office: 'Home office',
          city_change: 'Mudança de cidade',
          cities: 'Cidades',
          work_another_city: 'Trabalhar em outra cidade'
        }
      },
      pretensions: {
        title: 'Pretensões',
        fields: {
          intended_functions: 'Funções pretendidas',
          intended_experience_time: 'Tempo de experiência',
          intended_salary: 'Salário pretendido',
          internship_acceptance: 'Aceita estágio?'
        },
        labels: {
          intended_salary_label: 'Salário pretendido',
          internship_not_acceptance_label: 'Não aceita estágio',
          internship_acceptance_label: 'Aceita estágio',
          current_not_employee_label: 'Desempregado no momento',
          current_employee_label: 'Empregado no momento'
        }
      },
      education: {
        title: 'Escolaridade',
        fields: {
          course: 'Curso',
          institution: 'Instituição',
          level: 'Nível',
          year_of_training: 'Ano de formação',
          other_courses: 'Outros cursos',
          completed: 'Completo',
          notCompleted: 'Não completo'
        },
        labels: {
          courses: 'Outros Cursos:',
          languages: 'Idiomas:',
          hour: 'hora',
          hours: 'horas'
        }
      },
      contact: {
        title: 'Contato',
        fields: {
          email: 'E-mail',
          phone: 'Telefone'
        }
      },
      skill: {
        sectionTitle: 'Habilidades'
      },
      observation: {
        sectionTitle: 'Observações',
        labels: {
          available_to_work: 'Disponibilidade para trabalhar',
          available_to_relocate: 'Disponibilidade para morar em',
          available_to_travel: 'Disponibilidade para viajar',
          my_experience: 'Minhas experiências:'
        },
        yes: 'Sim',
        no: 'Não'
      },
      cv_option: {
        title: 'Parecer do currículo',
        sectionTitle: 'Parecer',
        fields: {
          include_cv_option: 'incluir parecer do recrutador'
        }
      }
    },
    actionButtons: {
      generatePDF: 'GERAR PDF',
      print: 'IMPRIMIR'
    }
  },
  createWorkspaceModal: {
    lugarh: {
      title: 'Adicionar workspace',
      subtitle: 'Insira as informações abaixo para prosseguir',
      stepOne: '1. Dados básicos',
      stepTwo: '2. Usuários'
    }
  },
  deleteWorkspaceModal: {
    lugarh: {
      title: 'Excluir workspace',
      subtitle: 'Esta ação é permanente, você tem certeza que deseja excluir?',
      back: 'Cancelar',
      delete: 'Quero excluir',
      requestMessage: {
        '204': 'Workspace excluído com sucesso.',
        '401': 'Falha na autenticação ou credenciais inválidas.',
        '403':
          'O espaço de trabalho não pode ser excluído porque está vinculado a um ou mais trabalhos.',
        '404': 'Espaço de trabalho não encontrado.',
        '500': 'Erro interno do servidor.'
      },
      loading: 'Processando...'
    }
  },
  updateWorkspaceModal: {
    lugarh: {
      title: 'Editar workspace',
      subtitle: 'Altere as informações abaixo para prosseguir',
      stepOne: '1. Dados básicos',
      stepTwo: '2. Usuários',
      requestMessage: {
        '200': 'Workspace Alterado com sucesso.',
        '401': 'Falha na autenticação ou credenciais inválidas.',
        '403': 'O espaço de trabalho não pode ser Alterado.',
        '404': 'Espaço de trabalho não encontrado.',
        '500': 'Erro interno do servidor.'
      }
    }
  },
  duplicateWorkspaceModal: {
    title: 'Duplicar para workspace',
    button: 'Duplicar para workspace'
  },
  createUserModal: {
    lugarh: {
      title: 'Adicionar Usuário',
      subtitle: 'Insira as informações abaixo para prosseguir',
      stepOne: '1. Dados do usuário',
      stepTwo: '2. Selecione os workspaces'
    }
  },
  candidateDatabaseSpreadsheet: {
    header: {
      candidates: 'Candidatos',
      locale: 'Localidade',
      intended_functions: 'Funções pretendidas',
      salary_expectation: 'Pretensão salarial',
      actions: 'Ações'
    }
  },
  candidateDetailsModal: {
    disabledOrignalCvTab:
      'Este candidato não possui currículo cadastrado no BNE.',
    tabOriginalCv: 'Currículo Original',
    tabEditedCv: 'Currículo Editado',
    tabHistory: 'Histórico do Candidato',
    tabExport: 'Exportar Currículo',
    tabApply: 'Aplicar para Vaga',
    messages: {
      'Candidate not found': 'Oops! Candidato não encontrado.',
      missingUuid: 'O UUID do candidato não foi encontrado.',
      yes: 'Sim',
      no: 'Não',
      notInformed: 'Não informado',
      neighborhoodNotInformed: 'Bairro não informado',
      locationNotInformed: 'Localidade não informada',
      year: 'ano',
      years: 'anos',
      no_professional_experiences: 'Não possui experiência adicionada.',
      no_educational_experiences: 'Não possui escolaridade adicionada.',
      no_about_candidate: 'Não possui descrição adicionada.',
      no_personal_data: 'Não possui dados pessoais adicionados.',
      no_address: 'Não possui endereço adicionado.',
      no_contact: 'Não possui contato adicionado.',
      no_intended: 'Não possui pretensão adicionada.',
      no_skills: 'Não possui habilidade adicionada.',
      no_disponibility: 'Não possui disponibilidade adicionada.',
      no_courses: 'Não possui curso adicionado.',
      no_languages: 'Não possui linguagem adicionada.',
      no_experience_time: 'Sem experiência'
    },
    sections: {
      educational_experiences: {
        title: 'Escolaridade',
        academicActivities: 'Descrição das atividades acadêmicas',
        institution: 'Instituição',
        startDate: 'Data de início',
        endDate: 'Data de fim',
        city: 'Cidade',
        conclusion: 'Conclusão'
      },
      professional_experiences: {
        title: 'Experiência profissional',
        startDate: 'De {{startDate}}',
        endDate: 'até {{formattedEndDate}}',
        noEndDate: 'até o momento',
        year: 'ano',
        years: 'anos',
        month: 'mês',
        months: 'meses',
        day: 'dia',
        days: 'dias',
        duties: 'Atribuições',
        companyActivity: 'Atividade da empresa',
        lastSalary: 'Último salário'
      },
      about_candidate: {
        title: 'Sobre'
      },
      personal_data: {
        title: 'Dados pessoais',
        cpf: 'CPF',
        children: 'Filhos',
        disability: 'Pessoa com deficiência',
        driveNumber: 'Veículos',
        license: 'Habilitação',
        linkedin: 'Linkedin',
        height: 'Altura',
        weight: 'Peso',
        race: 'Raça',
        vehicles: 'Veículos',
        mobility: 'Mobilidade',
        license_type: 'Tipo de habilitação',
        license_number: 'Número da habilitação',
        vehicle_name: 'Nome do veículo',
        vehicle_year: 'Ano do veículo'
      },
      address: {
        title: 'Endereço',
        address: 'Endereço',
        cep: 'CEP',
        complement: 'Complemento'
      },
      contacts: {
        title: 'Contato',
        phone: 'Telefone',
        email: 'E-mail',
        has_whatsapp: 'Possui Whatsapp',
        dont_have_whatsapp: 'Não possui whatsapp',
        landline_contact_ddd_phone: 'DDD',
        landline_contact_phone: 'Telefone fixo para recados',
        landline_contact_ddd_mobile: 'DDD',
        landline_contact_mobile: 'Telefone celular para recados',
        landline_contact_phone_name: 'Falar com'
      },
      intended: {
        title: 'Pretensões',
        intended_functions: 'Funções pretendidas',
        experience_time: 'Tempo de experiência',
        salary_expectation: 'Salário pretendido',
        currently_employed: 'Empregado no momento',
        accept_internship: 'Aceita estágio?',
        accept_home_office: 'Aceita home office?'
      },
      skills: {
        title: 'Habilidades'
      },
      disponibility: {
        title: 'Disponibilidades',
        to_trip: 'Para viagem',
        work_another_city: 'Trabalhar em outra cidade',
        city_relocation: 'Mudança de cidade'
      },
      courses: {
        title: 'Outros cursos',
        institution: 'instituição',
        endDate: 'Ano do término',
        city: 'Cidade'
      },
      languages: {
        title: 'Idiomas'
      }
    }
  },
  editResume: {
    modal: {
      deleteExperience: {
        title: 'Excluir experiência',
        subtitle:
          'Você tem certeza que deseja excluir esta experiência do CV? Esta ação não é reversível.'
      },
      leaveWithoutSaving: {
        title: 'Deseja sair sem salvar as alterações?',
        subtitle:
          'Você perderá todas as alterações feitas no CV. Tem certeza que deseja sair'
      }
    },
    button: {
      edit: {
        all_sections: 'EDITAR TODOS OS CAMPOS',
        section: 'EDITAR',
        keep_editing: 'CONTINUAR EDITANDO',
        leave_without_saving: 'SAIR SEM SALVAR',
        back: 'VOLTAR'
      },
      save: 'SALVAR ALTERAÇÕES',
      saving: 'SALVANDO...',
      cancel: 'CANCELAR',
      add: {
        experience: 'ADICIONAR EXPERIÊNCIA',
        educational_experience: 'ADICIONAR ESCOLARIDADE',
        certificate: 'ADICIONAR CERTIFICAÇÃO',
        language: 'ADICIONAR IDIOMA',
        skill: 'ADICIONAR HABILIDADE',
        vehicle: 'ADICIONAR VEÍCULO',
        intended_role: 'ADICIONAR FUNÇÃO PRETENDIDA'
      },
      delete: 'EXCLUIR'
    },
    sections: {
      professional_experiences: {
        company: 'Empresa *',
        starting_date: 'Data de entrada *',
        ending_date: 'Data de saída',
        details: 'Atribuições *',
        function: 'Função exercida *',
        company_activity: 'Atividade da empresa',
        salary: 'Último salário *',
        currently_working_here: 'Trabalho aqui atualmente',
        placeholder_company: 'Digite o nome da empresa',
        placeholder_details: 'Escreva as atividades realizadas na empresa',
        placeholder_function: 'Digite a função',
        placeholder_salary: '0,00'
      },
      educational_experiences: {
        course: 'Nome do curso *',
        completion_year: 'Ano de conclusão *',
        institution: 'Nome da instituição *',
        grade: 'Nível de formação *',
        status: 'Situação do curso *',
        working_hours: 'Carga horária *',
        city: 'Cidade *',
        placeholder_course: 'Digite o nome do curso',
        placeholder_institution: 'Digite o nome da instituição',
        placeholder_city: 'Nome da cidade',
        placeholder_completion_year: '0000'
      },
      personal_info: {
        gender: 'Gênero',
        birthdate: 'Data de nascimento',
        height: 'Altura (cm)',
        weight: 'Peso',
        race: 'Raça',
        firstname: 'Nome',
        lastname: 'Sobrenome',
        fullname: 'Nome completo *',
        has_children: 'Filhos',
        marital_status: 'Estado civil',
        cpf: 'CPF',
        profile_picture: 'Foto',
        driving_license: 'Habilitação',
        driving_license_number: 'Número da habilitação',
        has_disability: 'Possui alguma deficiência (PCD)?',
        mobility: 'Mobilidade',
        enterNumber: 'Insira o número',
        vehicles: {
          type: 'Veículo',
          name: 'Nome do veículo',
          year: '0000',
          model: 'Nome do modelo'
        },
        disability: 'Qual tipo de deficiência?',
        disability_value: {
          'Sim (Física)': 'Sim (Física)',
          'Sim (Auditiva)': 'Sim (Auditiva)',
          'Sim (Visual)': 'Sim (Visual)',
          'Sim (Mental)': 'Sim (Mental)',
          'Sim (Múltipla)': 'Sim (Múltipla)',
          'Sim (Reabilitado)': 'Sim (Reabilitado)',
          'Não Informado': 'Não Informado'
        },
        placeholder_fullname: 'Nome completo'
      },
      about: {
        cv_observation: 'Descrição',
        placeholder_cv_observation: 'Insira uma descrição'
      },
      address: {
        address_complement: 'Complemento',
        number: 'Número',
        neighborhood: 'Bairro',
        street: 'Endereço',
        postal_code: 'CEP',
        city: 'Cidade',
        placeholder_city: 'Digite e selecione sua cidade',
        placeholder_neighborhood: 'Digite seu bairro',
        placeholder_street: 'Nome da rua, avenida e etc',
        placeholder_address_complement: 'Insira se houver'
      },
      contacts: {
        ddd_mobile: 'DDD',
        mobile: 'Celular',
        has_whatsApp: 'Possuo whatsapp',
        ddd_phone: 'DDD',
        phone: 'Telefone',
        email: 'E-mail',
        linkedin: 'Linkedin',
        message_contacts: {
          contact_type: 'Contato para recado',
          ddd_landline_contact_phone: 'DDD',
          landline_contact_phone: 'Telefone fixo para recados',
          ddd_mobile_contact_phone: 'DDD',
          mobile_contact_phone: 'Telefone celular para recados',
          speak_with: 'Falar com',
          placeholder_landline_contact_phone: '00000-0000',
          placeholder_ddd_landline_contact_phone: '(00)',
          placeholder_mobile_contact_phone: '00000-0000',
          placeholder_ddd_mobile_contact_phone: '(00)',
          placeholder_speak_with: 'Nome do contato'
        },
        placeholder_ddd_mobile: '(00)',
        placeholder_mobile: '00000-0000',
        placeholder_ddd_phone: '(00)',
        placeholder_phone: '00000-0000',
        placeholder_email: 'exemplo@email.com.br',
        placeholder_linkedin: 'Insira a URL do seu perfil'
      },
      expectations: {
        salary_expectation: 'Pretensão salarial',
        is_working: 'Empregado no momento',
        intended_roles: {
          function: 'Função/Cargo Pretendido',
          experience_time: 'Experiência nessa função:',
          placeholder_function: 'Digite sua função'
        },
        accept_internship: 'Aceita estágio?',
        accept_home_office: 'Aceita home office?',
        placeholder_salary_expectation: '0,00'
      },
      availability: {
        city_relocation: 'Mudança de cidade',
        location: 'Cidade',
        trip_disponibility: 'Você tem disponibilidade para viagens?',
        work_another_city:
          'Você tem disponibilidade para trabalhar em outras cidades?',
        availability_period: 'Período de trabalho',
        availability_locations: 'Localidades de interesse',
        placeholder_location: 'Digite e selecione o nome da cidade'
      },
      certificates: {
        course: 'Nome do curso',
        completion_year: 'Ano de conclusão',
        institution: 'Nome da instituição',
        grade: 'Grau',
        status: 'Situação',
        working_hours: 'Carga horária',
        city: 'Cidade',
        placeholder_course: 'Digite o nome do curso',
        placeholder_completion_year: '0000',
        placeholder_institution: 'Digite o nome da instituição',
        placeholder_working_hours: '00',
        placeholder_city: 'Digite o nome da cidade'
      },
      languages: {
        language: 'Idioma',
        fluency: 'Fluência'
      },
      skills: {
        description: 'Habilidade',
        placeholder_description: 'Nome da habilidade'
      }
    },
    messages: {
      success: {
        editedCandidate: 'Candidato atualizado com sucesso!',
        createdCandidate: 'Candidato criado com sucesso!'
      },
      error: {
        editedCandidate: 'Erro ao atualizar candidato!',
        createdCandidate:
          'Erro ao criar candidato, tente novamente mais tarde!',
        fullname: {
          write_fullname: 'Escreva seu nome completo'
        },
        date: {
          birthdate: {
            min: 'A idade deve ser de, no mínimo, 14 anos',
            max: 'A data não pode exceder 100 anos'
          },
          starting: {
            min: 'A data não pode exceder 100 anos',
            max: 'A data não pode ser futura'
          },
          ending: {
            min: 'A data de término deve ser posterior à data de início',
            max: 'A data não pode ser futura'
          }
        }
      },
      minimumWage: 'O valor deve ser de R$ {{number}} no mínimo',
      notInformed: 'Não informado',
      select: 'Selecione',
      disabledFuncionality: 'Em breve',
      required: 'Campo obrigatório',
      yes: 'Sim',
      no: 'Não',
      years: 'anos',
      months: 'meses',
      patterns: {
        address: {
          number: 'Deve ser um número entre 1 e 5 dígitos',
          postcode: 'CEP inválido'
        },
        fullname: {
          invalid_characters: 'Nome contém caracteres inválidos'
        }
      }
    },
    options: {
      disability: {
        notInformed: 'Não informado',
        select: 'Selecione',
        Física: 'Física',
        Auditiva: 'Auditiva',
        Visual: 'Visual',
        Mental: 'Mental',
        Múltipla: 'Múltipla',
        Reabilitado: 'Reabilitado'
      },
      languages: {
        notInformed: 'Não informado',
        select: 'Selecione',
        Alemão: 'Alemão',
        'Chinês Mandarim': 'Chinês Mandarim',
        Espanhol: 'Espanhol',
        Francês: 'Francês',
        Inglês: 'Inglês',
        Italiano: 'Italiano',
        Japonês: 'Japonês',
        Português: 'Português',
        Russo: 'Russo'
      },
      languagesLevel: {
        notInformed: 'Não informado',
        Básico: 'Básico',
        Intermediário: 'Intermediário',
        Avançado: 'Avançado',
        Fluente: 'Fluente'
      },
      maritalStatus: {
        notInformed: 'Não informado',
        select: 'Selecione',
        'Solteiro(a)': 'Solteiro(a)',
        'Casado(a)': 'Casado(a)',
        'Separado(a) Judicialmente ou Desquitado(a)':
          'Separado(a) Judicialmente ou Desquitado(a)',
        'Divorciado(a)': 'Divorciado(a)',
        'Viúvo(a)': 'Viúvo(a)',
        'Amasiado(a)': 'Amasiado(a)',
        Outro: 'Outro'
      },
      schoolingDescription: {
        notInformed: 'Não informado',
        select: 'Selecione',
        'Ensino Fundamental Incompleto': 'Ensino Fundamental Incompleto',
        'Ensino Fundamental Completo': 'Ensino Fundamental Completo',
        'Ensino Médio Incompleto': 'Ensino Médio Incompleto',
        'Ensino Médio Completo': 'Ensino Médio Completo',
        'Técnico/Pós-Médio Incompleto': 'Técnico/Pós-Médio Incompleto',
        'Técnico/Pós-Médio Completo': 'Técnico/Pós-Médio Completo',
        'Tecnólogo Incompleto': 'Tecnólogo Incompleto',
        'Tecnólogo Completo': 'Tecnólogo Completo',
        'Superior Incompleto': 'Superior Incompleto',
        'Superior Completo': 'Superior Completo',
        'Pós Graduação / Especialização': 'Pós Graduação / Especialização',
        Mestrado: 'Mestrado',
        Doutorado: 'Doutorado',
        'Pós-Doutorado': 'Pós-Doutorado'
      },
      schoolingStatus: {
        notInformed: 'Não informado',
        select: 'Selecione',
        Cursando: 'Cursando',
        Trancado: 'Trancado',
        Cancelado: 'Cancelado'
      },
      workAvailability: {
        notInformed: 'Não informado',
        select: 'Selecione',
        Viagem: 'Viagem',
        Domingo: 'Domingo',
        Sábado: 'Sábado',
        Noite: 'Noite',
        Tarde: 'Tarde',
        Manhã: 'Manhã'
      },
      gender: {
        notInformed: 'Não informado',
        select: 'Selecione',
        male: 'Masculino',
        female: 'Feminino',
        Masculino: 'Masculino',
        Feminino: 'Feminino'
      },
      race: {
        notInformed: 'Não informado',
        select: 'Selecione',
        Branco: 'Branco',
        Preto: 'Preto',
        Pardo: 'Pardo',
        Amarelo: 'Amarelo'
      }
    },
    dateFormat: 'DD/MM/YYYY'
  },
  editUserModal: {
    lugarh: {
      title: 'Editar Usuário',
      subtitle: 'Insira as informações abaixo para prosseguir',
      stepOne: '1. Dados do usuário',
      stepTwo: '2. Selecione os workspaces'
    }
  },
  changeEnvironmentModal: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Escolha um workspace para trabalhar',
      actions: {
        change: 'Trocar Workspace',
        manage: 'Gerenciar Workspaces'
      }
    }
  },
  singleSelectableList: {
    lugarh: {
      placeholder: {
        search: 'Pesquisar por nome'
      },
      searchNotFound: 'Nenhum item encontrado'
    },
    recrud: {
      placeholder: {
        search: 'Pesquisar por nome'
      },
      searchNotFound: 'Nenhum item encontrado'
    }
  },
  moveWorkspaceModal: {
    lugarh: {
      modalTitle: 'Mover vaga para outro Workspace',
      modalSubtitle: 'Selecione o workspace para onde deseja mover a vaga',
      submitButton: 'Mover Vaga'
    }
  },
  resetPassword: {
    lugarh: {
      form: {
        title: 'Redefinir Senha',
        password: {
          show: 'Mostrar',
          hide: 'Esconder'
        },
        newPassword: {
          placeholder: 'Nova Senha'
        },
        confirmNewPassword: {
          placeholder: 'Confirmar Nova Senha'
        },
        submitButton: 'Redefinir',
        errors: {
          newPasswordMin: 'Mínimo 6 caracteres',
          newPasswordRequired: 'Nova senha é obrigatória',
          confirmNewPasswordRequired: 'Confirmação de senha é obrigatória',
          confirmNewPasswordMatch: 'As senhas não combinam'
        },
        goToLogin: 'Voltar para tela de login'
      },
      resetPasswordFail: 'Falha ao redefinir a senha.',
      resetPasswordSuccess: 'Senha redefinida com sucesso.'
    }
  },
  forgotPassword: {
    lugarh: {
      form: {
        title: 'Esqueci minha senha',
        email: {
          placeholder: 'E-mail'
        },
        submitButton: 'Recuperar senha',
        errors: {
          emailRequired: 'E-mail é obrigatório',
          emailInvalid: 'E-mail inválido'
        },
        goToLogin: 'Voltar para tela de login'
      }
    },
    metatags: {
      title: 'Lugarh | R&S',
      url: 'https://recrud.bne.com.br',
      description:
        'A próxima geração de gerenciamento de relacionamento de candidatos'
    },
    welcome: 'Seja bem-vindo à',
    firstParagraph: 'Gerencie os processos seletivos da sua empresa',
    secondParagraph: 'de forma simples e inteligente!',
    forgotPassword: 'Esqueci minha senha',
    cardParagraph:
      'Digite seu e-mail para receber instruções de alteração de senha',
    recoveryPassword: 'Recuperar senha',
    emailValidationError: 'Favor inserir um email válido',
    emailNotFound: 'E-mail não cadastrado, tente novamente',
    sendEmailFail: 'Falha ao enviar o e-mail de recuperação',
    sendEmailSuccess: 'E-mail de recuperação enviado com sucesso'
  },
  plans: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    selectPlanTitle: 'Selecione um plano',
    plansCardBasic: {
      title: 'Basic',
      subTitle: 'Para empresas com poucas vagas',
      planValue: 'R$29,90',
      planPeriod: '/mensal',
      feat1: 'Até 2 vagas por mês',
      feat2: 'Usuários ilimitados',
      feat3: 'Candidatos ilimitados'
    },
    plansCardSmart: {
      title: 'Smart',
      subTitle: 'Para empresas com médio volume de vagas',
      planValue: 'R$49,90',
      planPeriod: '/mensal',
      feat1: 'Até 5 vagas por mês',
      feat2: 'Usuários ilimitados',
      feat3: 'Candidatos ilimitados'
    },
    plansCardAdvanced: {
      title: 'Advanced',
      subTitle: 'Para empresas com maior volume de vagas',
      planValue: 'R$69,90',
      planPeriod: '/mensal',
      feat1: 'Até 10 vagas por mês',
      feat2: 'Usuários ilimitados',
      feat3: 'Candidatos ilimitados'
    },
    plansCardEnterprise: {
      title: 'Enterprise',
      subTitle: 'Para um grande volume de vagas',
      planValue: 'Cotação customizada',
      planPeriod: '',
      feat1: 'Acima de 10 vagas por mês',
      feat2: 'Usuários ilimitados',
      feat3: 'Candidatos ilimitados',
      selectButton: 'Fale com um especialista'
    },
    selectButton: 'Comece já'
  },
  checkoutPlan: {
    metatags: {
      title: 'string',
      description: 'string',
      url: 'string'
    },
    companyFormTitle: 'Dados da Empresa',
    paymentFormTitle: 'Método de pagamento',
    creditCardLabel: 'Cartão de crédito',
    cardNumber: 'Número do cartão',
    cvv: 'CVV',
    expirationMonth: 'Mês de validade',
    expirationYear: 'Ano de validade',
    cardHolderName: 'Titular do cartão',
    changePlanButton: 'Trocar de plano',
    paymentButton: 'Contratar',
    cardTokenizationError: 'Verifique os dados do cartão de crédito',
    iugoCardTokenizationFailure:
      'Ocorreu um erro interno ao processar o cartão',
    billingAddressFromTitle: 'Endereço de Cobrança',
    phone: 'Telefone',
    cpfCnpj: 'CPF/CNPJ',
    zipcode: 'CEP',
    district: 'Bairro',
    street: 'Logradouro',
    number: 'Número',
    complement: 'Complemento',
    city: 'Cidade',
    state: 'UF'
  },
  notAllowed: {
    lugarh: {
      title: 'Você não possui permissão para acessar essa página!',
      description:
        'Entre em contato com o administrador da sua conta para solicitar o acesso.'
    }
  },
  myProfile: {
    banner: {
      title: 'Editar Perfil',
      text: 'Edite suas informações pessoais, senhas e muito mais!'
    },
    form: {
      firstname: 'Primeiro nome',
      lastname: 'Último nome',
      email: 'E-mail',
      password: 'Senha',
      confirmPassword: 'Confirme a senha',
      save: 'Salvar Alterações!',
      successEditMessage: 'Alterações salvas com sucesso!'
    },
    confirmationModal: {
      wrongPassword: 'Senha incorreta',
      failToSend: 'Falha ao enviar e-mail',
      passwordConfirmationSucess:
        'Senha confirmada. Um código foi enviado para o seu novo e-mail',
      unavailable: 'Não é possível realizar a operação neste momento',
      wrongCode: 'Erro ao validar o código'
    }
  },
  candidate: {
    lugarh: {
      sectionTab: {
        notDefined: 'Ops! Nenhuma opção foi selecionada no menu lateral.'
      },
      basicInfo: {
        createdAt: 'Cadastro',
        updatedAt: 'Última atualização',
        years: 'anos',
        missingData: {
          title: 'Cargo não informado',
          location: 'Localização não informada',
          age: 'Nascimento não informado',
          salaryExpectation: 'Salário não informado',
          email: 'E-mail não informado',
          phone: 'Telefone não informado',
          linkedin: 'Linkedin não informado'
        },
        copyToClipboard: {
          success: 'Copiado com sucesso!',
          error: 'Erro ao copiar. Informação não fornecida pelo candidato.',
          errorWhileCopying: 'Erro ao copiar.'
        }
      },
      asideSectionNav: {
        curriculum: 'Currículo',
        history: 'Histórico',
        export: 'Exportar',
        apply: 'Aplicar para vaga'
      }
    }
  },
  themeTooltipSimple: {
    lugarh: {
      disabledTab: 'Aba Inativa'
    }
  },
  addCandidate: {
    lugarh: {
      title: 'Adicionar Candidato',
      subtitle:
        'Este é o seu painel de dados gerais do processo seletivo da sua empresa',
      submitFormButton: 'SALVAR INFORMAÇÕES',
      loadingCreate: 'Salvando informações...',
      personalDataBox: {
        title: 'Dados pessoais',
        firstname: 'Primeiro nome *',
        lastname: 'Último nome *',
        birthdate: 'Data de nascimento',
        maritalStatus: 'Estado civil',
        dateFormat: 'DD/MM/YYYY',
        maritalStatusOptions: {
          select: 'Selecione',
          divorced: 'Divorciado(a)',
          married: 'Casado(a)',
          single: 'Solteiro(a)',
          widowed: 'Viúvo(a)',
          other: 'Outro(a)'
        },
        sex: 'Gênero',
        sexOptions: {
          female: 'Feminino',
          male: 'Masculino',
          other: 'Outro'
        },
        phone: 'Telefone celular',
        email: 'Email',
        geoLocation: 'Localização *',
        cityRelocation: 'Possui diponibilidade para mudança?',
        cityRelocationOptions: {
          yes: 'Sim',
          no: 'Não'
        },
        salary_expectation: 'Expectativa salarial',
        linkedin_profile: 'LinkedIn',
        facebook_profile: 'Facebook',
        twitter_profile: 'Twitter',
        origin: 'Origem do candidato',
        about: 'Sobre *'
      },
      languageBox: {
        title: 'Idioma',
        language: 'Idioma *',
        level: 'Nível *',
        levelOptions: {
          basic: 'Básico',
          intermediate: 'Intermediário',
          fluent: 'Avançado',
          native: 'Nativo'
        },
        button: 'Adicionar novo idioma',
        deleteButton: 'Excluir'
      },
      educationBox: {
        title: 'Escolaridade',
        course: 'Curso *',
        institution: 'Instituição',
        startDate: 'Início *',
        endDate: 'Fim *',
        level: 'Nível *',
        dateFormat: 'DD/MM/YYYY',
        levelOptions: {
          incomplete_middleschool: 'Fundamental Incompleto',
          middleschool: 'Fundamental Completo',
          incomplete_highschool: 'Ensino medio Incompleto',
          highschool: 'Ensino medio completo',
          undergraduate: 'Superior',
          postgraduate: 'Pós graduado',
          masters: 'Mestrado',
          doctorate: 'Doutorado'
        },
        description: 'Sobre',
        button: 'Adicionar novo curso',
        deleteButton: 'Excluir'
      },
      certificateBox: {
        title: 'Certificados e conquistas',
        name: 'Nome *',
        hours: 'Carga horária *',
        button: 'Adicionar nova conquista',
        deleteButton: 'Excluir'
      },
      experienceBox: {
        title: 'Experiência profissional',
        role: 'Cargo *',
        company: 'Empresa',
        startDate: 'Data de início *',
        endDate: 'Data de término',
        description: 'Descrição das atividades',
        currentJob: 'Estou trabalhando nesta empresa atualmente *',
        button: 'Adicionar nova experiência',
        deleteButton: 'Excluir',
        dateFormat: 'DD/MM/YYYY'
      },
      otherInformationBox: {
        title: 'Outras informacões',
        other_info: 'Outras informacões'
      },
      attachmentsBox: {
        title: 'Envio de arquivos',
        button: 'Adicionar arquivo',
        attachmentError:
          'Somente arquivos em PDF, PNG, JPEG e JPG com no máximo 3mb são permitidos.',
        underlineText: 'Clique para fazer upload',
        description: 'ou arraste e solte o arquivo aqui',
        fileTypes: 'PDF, PNG ou JPG (max. 3MB)',
        success: 'Completo',
        uploadFailed: 'Upload falhou',
        largeDocumentError: 'Documento muito pesado  •  Falhou'
      },
      messages: {
        success: 'Candidato adicionado com sucesso!'
      },
      submitButton: 'Continuar'
    },
    button: {
      addAttachments: 'Adicionar arquivo'
    },
    banner: {
      title: 'Adicionar Candidato',
      text: 'Adicionar candidato na base de dados'
    },
    form: {
      email: 'E-mail',
      firstname: 'Primeiro nome',
      lastname: 'Último nome',
      about: 'Sobre',
      geoLocation: 'Localização',
      phone: 'Telefone',
      maritalStatus: 'Estado Civil',
      sex: 'Gênero',
      birthdate: 'Data de Nascimento',
      salaryExpectation: 'Expectativa Salarial',
      cityRelocation: 'Possui diponibilidade para mudança?',
      linkedinProfile: 'Linkedin',
      facebookProfile: 'Facebook',
      twitterProfile: 'Twitter',
      save: 'Salvar Alterações!',
      successMessage: 'Alterações salvas com sucesso!'
    },
    titleBox: {
      personalData: 'Dados pessoais',
      language: 'Idioma',
      education: 'Educação',
      certificatesAndAchievements: 'Certificados e conquistas',
      experience: 'Experiência profissional',
      otherInformation: 'Outras informações',
      attachments: 'Envio de arquivos'
    },
    languageBox: {
      language: 'Idioma',
      level: 'Nível'
    },
    educationBox: {
      course: 'Curso',
      institution: 'Instituição',
      startDate: 'Data de início',
      endDate: 'Data de finalização',
      level: 'Nível',
      about: 'Sobre'
    },
    certificateBox: {
      name: 'Nome',
      hours: 'Carga horária'
    },
    experienceBox: {
      role: 'Cargo',
      company: 'Empresa',
      startDate: 'Data de início',
      endDate: 'Data de finalização',
      currentJob: 'Emprego atual',
      description: 'Descrição das atividades'
    },
    otherInformation: 'Outras informacões',
    attachmentSubTitleDescription:
      'Por favor, anexe os documentos comprovativos. [Ex.: Currículo, CV e/ou Carta de Apresentação]',
    attachmentSubTitleFileDescription: 'Anexos',
    attachmentError:
      'Somente arquivos em PDF, PNG, JPEG e JPG com no máximo 5mb são permidos.',
    sexOptions: {
      female: 'Feminino',
      male: 'Masculino',
      other: 'Outro'
    },
    maritalStatusOptions: {
      select: 'Selecione',
      divorced: 'Divorciado(a)',
      married: 'Casado(a)',
      single: 'Solteiro(a)',
      widowed: 'Viúvo(a)',
      other: 'Outro(a)'
    },
    cityRelocationOptions: {
      yes: 'Sim',
      no: 'Não'
    }
  },
  applyJobModal: {
    lugarh: {
      title: 'Aplicar candidato a vaga',
      subtitle:
        'Selecione a vaga e a etapa do fluxo de contratação para aplicar o candidato.',
      question: {
        title: 'Você deseja aplicar este candidato para uma vaga?',
        subtitle:
          'Aplicar este candidato irá enviá-lo para o processo seletivo da vaga.',
        applyToJob: 'SIM, DESEJO APLICAR',
        noApplyToJob: 'APENAS FINALIZAR'
      }
    }
  },
  candidateDetail: {
    movedTo: 'movido para a etapa {{stageName}} por {{authorName}}.',
    appliedTo: 'aplicou para vaga.',
    current: 'ATUALMENTE'
  },
  companySettings: {
    save: 'Salvar Alterações!',
    banner: {
      title: 'Configurações da Empresa',
      text: 'Edite informações sobre a empresa, página de talentos, dados sensíveis e muito mais!'
    },
    color: {
      companyPageHeaderColor: 'Cor do cabeçalho da página de carreiras',
      companyPageFontColor: 'Cor da fonte da página de carreiras',
      companyPageButtonColor: 'Cor dos botões da página de carreiras',
      companyPageButtonFontColor:
        'Cor da fonte dos botões da página de carreiras'
    },
    careerPageSetting: {
      title: 'Configurações da página Career',
      showHomeButtonTitle: 'Mostrar botão home',
      showHomeButtonText: 'Decidir se o candidato verá o botão home.',
      showMainPageTitle: 'Mostrar página principal',
      showMainPageText:
        'Decidir se o candidato será capaz de acessar a página principal.',
      careerPageLabel: 'Ativar?'
    },
    lgpd: {
      title: 'Dados pessoais / Dados sensíveis / LGPD',
      label: {
        question: 'Ativar?',
        required: 'Obrigatório?'
      },
      askPhoneTitle: 'Perguntar número de telefone',
      askPhoneText:
        'Decida se o candidato irá informar o número de telefone e se você irá tratar essa informação.',
      civilStatusTitle: 'Estado civil',
      civilStatusText:
        'Decida se o candidato irá informar seu estado civil e se você irá tratar essa informação.',
      askGenderTitle: 'Perguntar Gênero',
      askGenderText:
        'Decida se o candidato irá informar seu gênero e se você irá tratar essa informação.',
      askBirthdateTitle: 'Perguntar Data de Nascimento',
      askBirthdateText:
        'Decida se o candidato irá informar a data de nascimento e se você irá tratar essa informação.',
      askWagePretensionTitle: 'Perguntar Pretensão Salarial',
      askWagePretensionText:
        'Decida se o candidato irá informar sua pretensão salarial e se você irá tratar essa informação.',
      askMovingAvailabilityTitle: 'Perguntar disponibilidade para mudança',
      askMovingAvailabilityText:
        'Decida se o candidato irá informar sua disponibilidade para mudança e se você irá tratar essa informação.',
      askAboutLinkedinProfileTitle: 'Perguntar sobre o perfil LinkedIn',
      askAboutLinkedinProfileText:
        'Decida se o candidato irá informar seu perfil LinkedIn e se você irá tratar essa informação.',
      askAboutFacebookProfileTitle: 'Perguntar sobre o perfil Facebook',
      askAboutFacebookProfileText:
        'Decida se o candidato irá informar seu perfil Facebook e se você irá tratar essa informação.',
      askAboutTwitterProfileTitle: 'Perguntar sobre o perfil Twitter',
      askAboutTwitterProfileText:
        'Decida se o candidato irá informar seu perfil Twitter e se você irá tratar essa informação.',
      termsOfUseTitle: 'Termos de uso',
      termsOfUseText: 'Insira um link para termos de uso.'
    },
    companyForm: {
      name: 'Razão Social',
      fancyName: 'Nome fantasia',
      cnpj: 'CNPJ',
      description: 'Descrição da empresa',
      location: 'Localização',
      site: 'Link para o site de carreiras'
    },
    changesSaved: 'Alterações salvas!',
    messageChangesSaved:
      'A página da sua empresa foi alterada. Você pode verificá-la acessando o link:'
  },
  onboarding: {
    buttons: {
      next: 'Próximo',
      previous: 'Anterior',
      skip: 'Pular'
    },
    dashboard: {
      finishOnboardingButton: 'Finalizar Tour Inicial',
      content: [
        {
          title: 'Bem vindo ao Recrud',
          text: 'Como é a sua primeira vez aqui, iremos te auxiliar com este simples tutorial para que você conheça melhor o recrud e possa explorá-lo da melhor forma.'
        },
        {
          title: 'A barra de navegação',
          text: 'Está é a barra de navegação do sistema. Por aqui você pode acessar suas vagas, banco de candidatos e retornar para a dashboard.'
        },
        {
          title: 'A barra de pesquisa',
          text: 'Caso queira encontrar vagas ou candidatos específicos você pode procurar por aqui e economizar tempo.'
        },
        {
          title: 'O Painel de administração',
          text: 'Se você é um gerente, por aqui você poderá configurar a página da sua empresa, gerenciar seu fluxo de contratação e adicionar ou remover usuários da sua equipe.<br /><br />Se você é um recrutador, por aqui você poderá editar o seu perfil e alterar a sua senha.'
        },
        {
          title: 'A Dashboard',
          text: 'Acompanhe os dados do seu processo de contratação pela dashboard, como o tempo médio de contratação, os número da sua base de candidatos e muito mais.'
        },
        {
          title: 'O Recrud',
          text: 'Agora você conhece bem a parte inicial do sistema. Navega para as outras páginas para continuar o tutorial.'
        }
      ]
    },
    jobs: {
      finishOnboardingButton: 'Finalizar tour pela tela de vagas',
      content: [
        {
          title: 'Vagas',
          text: 'Este é o painel de vagas, onde todas as vagas ativas da sua empresa são exibidas para que os candidatos possam se inscrever. Vamos explorar mais detalhes sobre esta página.'
        },
        {
          title: 'Criar uma nova vaga',
          text: 'Precisa criar uma nova vaga? Para cadastrá-la, clique no botão indicado e preencha o formulário de cadastro!'
        },
        {
          title: 'Status da vaga',
          text: 'Uma vaga pode estar ativa ou inativa. Se a barra estiver verde, a vaga está ativa. Se estiver vermelha, está inativa. Os candidatos só podem se inscrever em vagas ativas.'
        },
        {
          title: 'Recrutadores',
          text: 'Você pode adicionar ou remover os recrutadores responsáveis por esta vaga, clicando em “Editar”'
        },
        {
          title: 'Candidatos',
          text: 'Aqui você poderá gerenciar e visualizar os candidatos que se candidataram nesta vaga.'
        },
        {
          title: 'Compartilhar vagas',
          text: 'No botão "Compartilhar", você encontrará os links disponíveis para compartilhar sua vaga em cada rede social.'
        },
        {
          title: 'Editar Vaga',
          text: 'No botão “Editar”, você pode alterar as informações da vaga, e também os recrutadores responsáveis da mesma.'
        },
        {
          title: 'Filtros',
          text: 'Se você tiver muitas vagas e desejar encontrá-las rapidamente, conte com o painel de filtros.'
        },
        {
          title: 'Visualização em Listas ou Cartões',
          text: 'Quer visualizar suas vagas em outro formato? Escolha aqui se prefere vê-las em cartões ou em formato de lista.'
        }
      ]
    },
    cadidatesDB: {
      finishOnboardingButton: 'Finalizar tour pelo banco de candidatos!',
      content: [
        {
          title: 'Candidatos',
          text: 'Este é seu banco de talentos. Todos os candidatos que se aplicaram as suas vagas, estarão por aqui!'
        },
        {
          title: 'Ordenar candidatos',
          text: 'Caso deseje ordenar os seus candidatos para facilitar sua busca, verifique aqui as opções de ordenação.'
        },
        {
          title: 'Filtrar candidatos',
          text: 'Muitos candidatos? Você pode usar o painel lateral para filtrar por campos específicos relacionados à educação, experiência, e outros critérios.'
        },
        {
          title: 'Pré visualização de currículo',
          text: 'Você não precisa acessar individualmente cada página de candidato. Clique aqui para visualizar um resumo das informações e facilitar sua avaliação.'
        },
        {
          title: 'Detalhes do candidato',
          text: 'Se a prévia não foi suficiente, você pode acessar todas as informações deste candidato clicando aqui para visualizar o currículo completo e o histórico.'
        },
        {
          title: 'Aplicar à uma vaga',
          text: 'Caso queira adicionar este candidato a uma vaga, você pode fazê-lo por aqui.'
        }
      ]
    },
    candidateDetail: {
      finishOnboardingButton: 'Finalizar tutorial!',
      content: [
        {
          title: 'Detalhe do candidato',
          text: 'Aqui você encontra todas as informações detalhadas sobre um candidato, incluindo histórico de inscrições, observações feitas pelos recrutadores e muito mais.'
        },
        {
          title: 'Histórico',
          text: 'No histórico do candidato você pode conferir todas as etapas que o candidatou passou em seu processo de contratação.'
        },
        {
          title: 'Aplicar para a Vaga',
          text: 'Se você possui permissão para gerenciar este processo, você poderá mover o candidato para outras etapas.'
        },
        {
          title: 'Avaliação do recrutador',
          text: 'Você pode conferir a nota atribuída por um recrutador. As notas são informações importantes durante o processo do candidato.'
        },
        {
          title: 'Avaliar candidato',
          text: 'Para avaliar o candidato, basta utilizar as estrelas.'
        },
        {
          title: 'Anotações',
          text: 'Caso queira registrar informações sobre o candidato, utilize a caixa de texto.'
        }
      ]
    },
    candidates: {
      finishOnboardingButton: 'Finalizar tutorial!',
      content: [
        {
          title: 'Candidatos',
          text: 'Estes são os candidatos que se inscreveram em uma vaga disponível. Aqui, você poderá gerenciá-los para cumprir as etapas do seu fluxo de contratação.'
        },
        {
          title: 'Etapas do fluxo de contratação',
          text: 'Esta barra exibe todas as etapas do Fluxo de Contratação atribuído a esta vaga. Aqui, você pode filtrar seus candidatos por etapa. Clique em uma etapa para ver os candidatos presentes nela.'
        },
        {
          title: 'Ordenar candidatos',
          text: 'Caso deseje ordenar os seus candidatos para facilitar sua busca, verifique aqui as opções de ordenação.'
        },
        {
          title: 'Filtrar candidatos',
          text: 'Muitos candidatos? Você pode usar o painel lateral para filtrar por campos específicos relacionados à educação, experiência, e outros critérios.'
        },
        {
          title: 'Pré visualização de currículo',
          text: 'Você não precisa acessar individualmente cada página de candidato. Clique aqui para visualizar um resumo das informações e facilitar sua avaliação.'
        },
        {
          title: 'Detalhes do candidato',
          text: 'Se a prévia não foi suficiente, você pode acessar todas as informações deste candidato clicando aqui para visualizar o currículo completo e o histórico.'
        },
        {
          title: 'Mover Candidato',
          text: 'Caso você já saiba qual etapa o candidato se encaixa, você pode fazer isto por aqui.'
        }
      ]
    },
    companySettings: {
      finishOnboardingButton: 'Finalizar tutorial!',
      content: [
        {
          title: 'Configurações da empresa',
          text: 'Configure por aqui a sua página de carreiras. Esta página será utilizada para exibir as suas vagas ativas.'
        },
        {
          title: 'Dados principais',
          text: 'Comece adicionando os dados principais. Não se preocupe, apenas o nome da empresa, localização e descrição serão exibidas na página de carreiras.'
        },
        {
          title: 'Logo da empresa',
          text: 'Adicione o logo da empresa para compor o cabeçalho da página. Recomendamos que tenha um tamanho mínimo de 300px de largura e que o fundo seja transparente.'
        },
        {
          title: 'Configure com as cores da empresa',
          text: 'Configure qual será a cor do cabeçalho, textos e botões da página de carreiras.'
        },
        {
          title: 'Dados sensíveis',
          text: 'Escolha quais informações adicionais deseja coletar dos candidatos, no momento em que forem se inscrever para as suas vagas.'
        }
      ]
    },
    companyTeam: {
      finishOnboardingButton: 'Finalizar tour pela tela de gerenciar time!',
      content: [
        {
          title: 'Equipe da sua empresa',
          text: 'Aqui, você poderá gerenciar os usuários da sua empresa e definir suas permissões.'
        },
        {
          title: 'Usuários',
          text: 'Os usuários podem ter perfis de acesso como administradores, gestores ou recrutadores. Somente os administradores têm permissão para editar as configurações da empresa, modificar os fluxos de contratação e gerenciar os usuários.'
        },
        {
          title: 'Status do usuário',
          text: 'Um usuário pode estar ativado ou bloqueado, e esta barra indicará seu status. Se estiver verde, o usuário está ativo. Se estiver vermelha, o usuário está bloqueado e não poderá acessar a plataforma.'
        },
        {
          title: 'Bloquear ou ativar usuários',
          text: 'Para bloquear ou reativar usuários basta clicar aqui.'
        },
        {
          title: 'Editar usuário',
          text: 'Aqui serve para editar um usuário incluído no Time. Você pode editar nome, e-mail, senha e permissões.'
        },
        {
          title: 'Excluir usuário',
          text: 'Para excluir um usuário, clique no ícone da lixeira.'
        },
        {
          title: 'Cadastrar um novo usuário',
          text: 'Para adicionar um novo usuário, basta clicar neste botão e preencher o formulário.'
        }
      ]
    },
    jobsEdit: {
      finishOnboardingButton: 'Finalizar tutorial!',
      content: [
        {
          title: 'Cadastro/Edição de Vagas',
          text: 'Configure por aqui as informações da sua vaga. Adicione os dados principais para iniciar.'
        },
        {
          title: 'Vincular a um Fluxo de Contratação',
          text: 'Para que sua vaga tenha um processo em etapas definido, crie um Fluxo de Contratação em “Fluxos de Contratação” que você encontra na engernagem no canto superior direito da tela e, posteriormente,  o vincule à esta vaga por aqui.'
        },
        {
          title: 'Perguntas personalizadas',
          text: 'Você poderá criar perguntas personalidas para serem exibidas ao candidato no momento da inscrição. Você poderá criar questões de única escolha, multipla escolha e texto aberto. As questões de única escolha podem ser marcadas como eliminatória, movendo o candidato para etapa “Proposta recusada” automaticamente caso responda incorretamente.'
        },
        {
          title: 'Recrutadores e Gestores',
          text: 'Arraste os recrutadores ou gestores disponíveis para fazerem parte do time do processo de contratação desta vaga e poderem tomar decisões e ações durante o processo.'
        },
        {
          title: 'Salvar',
          text: 'Salve as alterações realizadas para concretizar a sua vaga.'
        }
      ]
    },
    workflow: {
      finishOnboardingButton: 'Finalizar tutorial!',
      content: [
        {
          title: 'Fluxos de contratação',
          text: 'Aqui, você poderá criar e gerenciar os fluxos de contratação para cada vaga. Dessa forma, você saberá exatamente quais etapas seus candidatos percorrerão até a contratação.'
        },
        {
          title: 'Editar/visualizar o fluxo de contratação',
          text: 'Para editar ou visualizar um fluxo de contratação e só clicar aqui!'
        },
        {
          title: 'Excluir Fluxo de Contratação',
          text: 'Para excluir um fluxo de contratação, clique no ícone da lixeira. Um fluxo de contratação só pode ser excluído se não estiver vinculado a nenhuma vaga.'
        },
        {
          title: 'Cadastrar um novo fluxo de contratação',
          text: 'Para cadastrar um novo fluxo de contratação, é só clicar neste botão!'
        }
      ],
      add: 'Adicionar novo Fluxo de Contratação',
      update: 'Atualizar Fluxo de Contratação'
    },
    workflowEdit: {
      finishOnboardingButton: 'Finalizar tutorial!',
      content: [
        {
          title: 'Fluxo de contratação',
          text: 'Aqui você poderá gerenciar as etapas de seu fluxo de contratação. As etapas cadastradas aqui estarão disponíveis para que você movimente os candidatos durante o processo seletivo.'
        },
        {
          title: 'Etapas padrões',
          text: 'O Recrud já possui algumas etapas padrões que não podem ser excluídas. A primeira etapa é a “Disponível”. Cada candidato que se aplica a uma vaga começa nesta etapa. As outras etapas padrões são: Rejeitado na triagem, Aprovado na triagem, Proposta Recusada e Contratado.'
        },
        {
          title: 'Criar etapa',
          text: 'No Recrud você pode estruturar o processo seletivo da forma que fizer sentido para você. Para incluir uma nova etapa, basta clicar em "Criar nova etapa aqui".'
        },
        {
          title: 'Etapas personalizadas',
          text: 'As etapas personalizadas são identificadas pela cor laranja.'
        },
        {
          title: 'Excluir etapa personalizada',
          text: 'Etapas personalizadas poderão ser excluídas caso o fluxo de contratação não esteja vinculado à uma vaga.'
        },
        {
          title: 'Enviar email automático',
          text: 'Habilite esta funcionalidade caso deseje que o candidato receba o email configurado assim que for movido para a etapa.'
        },
        {
          title: 'Conteúdo do email automático',
          text: 'O conteúdo do email automático poderá ser alterado clicando neste botão. Você pode editá-lo como preferir.'
        }
      ]
    }
  },
  jobCard: {
    candidates: 'Candidatos',
    createNewVacancy: 'Criar nova vaga',
    edit: 'Editar',
    move: 'Mover',
    duplicate: 'Duplicar',
    share: 'Compartilhar',
    team: 'Equipe',
    noteam: 'Sem equipe selecionada',
    managers: 'Gestor',
    recruiter: 'Recrutador',
    toggleJob: 'Pausar/Retomar vaga',
    overdueJob: 'Vaga atrasada',
    closedJob: 'Vaga finalizada',
    stopJob: 'Pausar',
    startJob: 'Iniciar'
  },
  teamManagement: {
    title: 'Gerenciar Time',
    text: 'Crie e edite usuários para sua empresa',
    blockModal: {
      title: 'Confirmação',
      description: 'Deseja realmente <strong>bloquear</strong> o usuário ',
      undo: 'Cancelar',
      confirm: 'Sim, bloqueie!',
      success: 'Usuário bloqueado com sucesso!'
    },
    unblockModal: {
      title: 'Confirmação',
      description: 'Deseja realmente <strong>desbloquear</strong> o usuário ',
      undo: 'Cancelar',
      confirm: 'Sim, desbloqueie!',
      success: 'Usuário desbloqueado com sucesso!'
    },
    deleteModal: {
      title: 'Confirmação',
      description: 'Deseja realmente <strong>deletar</strong> o usuário ',
      undo: 'Cancelar',
      confirm: 'Sim, remover!',
      success: 'Usuário removido com sucesso!'
    },
    editModal: {
      success: 'Usuário atualizado com sucesso!'
    },
    addModal: {
      success: 'Usuário criado com sucesso!'
    }
  },
  teamCard: {
    createNewUser: 'Criar novo usuário',
    edit: 'Editar',
    block: 'Bloquear',
    reactivate: 'Reativar',
    delete: 'Deletar',
    profileOptions: {
      admin: 'Administrador',
      manager: 'Gestor',
      recruiter: 'Recrutador'
    },
    modalWorkspacesTitle: 'Workspaces vinculados',
    modalWorkspacesSubtitle:
      'Veja a lista de workspaces vinculados a este usuário.'
  },
  userModal: {
    edit: {
      title: 'Editar Usuário',
      confirm: 'Editar Usuário!'
    },
    add: {
      title: 'Adicionar Usuário',
      confirm: 'Criar Usuário!'
    },
    firstname: 'Nome',
    lastname: 'Sobrenome',
    email: 'E-mail',
    password: 'Senha',
    profile: 'Perfil',
    profileOptions: {
      admin: 'Administrador',
      manager: 'Gestor',
      recruiter: 'Recrutador'
    },
    emailEdit: 'Para alterar seu email, acesse a edição de perfil ou',
    clickHere: 'clique aqui'
  },
  hiringStages: {
    text: 'Clique em uma etapa para ver os candidatos que estão nesta etapa.'
  },
  jobHeader: {
    firstOption: 'Vagas atrasadas',
    secondOption: 'Ativas',
    thirdOption: 'Paradas',
    fourthOption: 'Todas',
    searchLabel: 'Filtrar por workspaces',
    selectLabel: 'Filtre por'
  },
  candidateHistory: {
    lugarh: {
      dateTimeConnector: 'às',
      applicationToMessage: 'aplicou para a vaga',
      movedToMessage: 'Foi movido para a etapa',
      stepAuthorConnector: 'por',
      stages: {
        Available: 'Disponível',
        'Rejected in Triage': 'Rejeitado na triagem',
        'Approved in Triage': 'Aprovado na triagem',
        'Failed in the selection process': 'Reprovado no processo de seleção',
        'Denied Offer': 'Proposta recusada',
        'Rejected during the process': 'Rejeitado durante o processo',
        'Approved in process': 'Aprovado no processo',
        'Solicitar documentos': 'Solicitar documentos'
      },
      noHistoryLogs: 'Este candidato ainda não possui logs de histórico',
      invalidCandidateUUID: 'Candidato inválido ou inexistente',
      missing: {
        author: 'Autor não informado',
        workspace: 'Workspace não informado'
      }
    }
  },
  candidateHeader: {
    lugarh: {
      title: 'Base geral de candidatos',
      subtitle: 'Este é o banco de currículos geral da sua empresa',
      filter: 'FILTRAR',
      addCandidate: 'ADICIONAR CANDIDATO',
      export: 'EXPORTAR',
      applyCandidates: 'APLICAR PARA VAGA',
      selectCandidates: 'Selecionar todos',
      noResults: {
        title:
          'Esta empresa ainda não possui currículos registrados na base geral.',
        subtitle: 'Você pode adicioná-los manualmente',
        geralSubtitle:
          'O workspace geral é somente para visualização. Para adicionar um candidato, você precisa acessar um workspace específico.',
        changeWorkspace: 'TROCAR WORKSPACE'
      },
      candidate: {
        apply: 'APLICAR PARA VAGA',
        detail: 'VER CURRÍCULO',
        preview: 'PREVIEW'
      },
      candidateListHeaders: {
        name: 'NOME',
        resumes: 'CURRÍCULOS',
        apply: 'APLICAR PARA VAGA'
      }
    },
    title: "Mostrando candidatos para '{{opportunity}}' na etapa '{{stage}}'",
    databaseTitle: 'Base de Dados de Candidatos',
    orderBy: 'Ordenar por',
    moveSelected: 'Mover selecionados para etapa',
    orderByOptions: {
      created_at: 'Data de Inclusão',
      name: 'Nome do candidato',
      firstname: 'Nome do candidato',
      review: 'Nota Média',
      applicationDate: 'Data de Aplicação'
    }
  },
  enhancedTable: {
    lugarh: {
      pagination: {
        rowsPerPage: 'Itens por página',
        of: 'de',
        moreThan: 'Mais que'
      }
    }
  },
  candidateList: {
    select: 'Mover para etapa',
    review: 'Nota Média',
    preview: 'Prévia',
    detail: 'Detalhe',
    apply: 'Aplicar para Vaga',
    add: 'Adicionar',
    addCandidate: 'Adicionar Candidato'
  },
  select: {
    acquisitionChannel: {
      0: 'Selecione'
    },
    vacancyAllocation: {
      0: 'Selecione',
      1: 'Híbrido',
      2: 'Presencial',
      3: 'Remoto',
      4: 'Não especificado'
    },
    education: {
      0: 'Fundamental incompleto',
      1: 'Fundamental',
      2: 'Médio incompleto',
      3: 'Médio',
      4: 'Superior',
      5: 'Pós-graduação',
      6: 'Mestrado',
      7: 'Doutorado'
    },
    language: {
      0: 'Básico',
      1: 'Intermediário',
      2: 'Fluente',
      3: 'Nativo'
    }
  },
  content: {
    whatsrecrud: {
      title: 'O que é o Recrud?',
      subtitle: 'Lorem ipsum'
    },
    privacypolicy: {
      title: 'Políticas de Privacidade',
      subtitle: 'Lorem ipsum'
    },
    terms: {
      title: 'Termos',
      subtitle: 'Lorem ipsum'
    }
  },
  candidatePreview: {
    about: 'Sobre',
    education: 'Formação',
    experience: 'Experiência',
    languages: 'Idiomas',
    closePreview: 'Fechar Preview',
    educationIn: 'em',
    current: 'atual',
    basic: 'Básico',
    intermediate: 'Intermediário',
    fluent: 'Fluente',
    native: 'Nativo',
    single: 'Solteiro',
    married: 'Casado',
    divorced: 'Divorciado',
    widowed: 'Viuvo',
    other: 'Outro',
    male: 'Masculino',
    female: 'Feminino'
  },
  languageLevelOptions: {
    basic: 'Básico',
    intermediate: 'Intermediário',
    fluent: 'Avançado',
    native: 'Nativo'
  },
  candidateFilter: {
    modalTitle: 'Filtrar Candidatos',
    getDataError:
      'Houve um problema ao buscar algumas informações. Por favor, tente reccaregar a página.',
    genderOptions: {
      other: 'Outro',
      male: 'Homem',
      female: 'Mulher'
    },
    civilStatusOptions: {
      single: 'Solteiro',
      married: 'Casado',
      divorced: 'Divorciado',
      widowed: 'Viuvo',
      other: 'Outro'
    },
    availableToMoveOptions: {
      true: 'Sim',
      false: 'Não'
    },
    languageLevelOptions: {
      basic: 'Básico',
      intermediate: 'Intermediário',
      fluent: 'Avançado',
      native: 'Nativo'
    },
    educationLevelOptions: {
      literate: 'Alfabetizado',
      withdrawn: 'Desistente',
      incomplete_middleschool: 'Fundamental Incompleto',
      middleschool: 'Fundamental Completo',
      incomplete_highschool: 'Ensino medio Incompleto',
      highschool: 'Ensino medio completo',
      undergraduate: 'Superior',
      postgraduate: 'Pós graduado',
      masters: 'Mestrado',
      doctorate: 'Doutorado'
    },
    buttons: {
      filter: 'Filtrar',
      filterCandidate: 'Filtrar candidatos',
      applyFilters: 'Aplicar filtros'
    },
    general: {
      title: 'Geral',
      clearFilters: 'Limpar Filtros',
      location: 'Localidade',
      age: {
        min: 'Idade mín',
        max: 'Idade max'
      },
      gender: 'Gênero',
      civilStatus: 'Estado Civil',
      salary: {
        min: 'Salário mín',
        max: 'Salário max'
      },
      availableToMove: 'Disponível para mudança?',
      workspacesMltiselect: 'Workspace(s)'
    },
    education: {
      title: 'Educação',
      name: 'Instituição de ensino',
      course: 'Curso',
      level: 'Nível'
    },
    experience: {
      title: 'Experiência',
      company: 'Empresa',
      position: 'Cargo',
      timeInYears: 'Tempo de trabalho em anos'
    },
    language: {
      title: 'Idioma',
      name: 'Idioma',
      level: 'Nível'
    }
  },
  aboutContainer: {
    goBack: 'Voltar',
    about: 'Sobre mim',
    education: 'Educação',
    birthdate: 'Data de nascimento',
    experience: 'Experiência Profissional',
    otherInfo: 'Outras Informações',
    languages: 'Idiomas',
    certificates: 'Certificados e Conquistas',
    certificateHours: '{{hours}} horas',
    personalInfo: 'Informação Pessoal',
    yes: 'Sim',
    not: 'Não',
    email: 'E-mail',
    phone: 'Telefone',
    localization: 'Localização',
    maritalStatus: 'Estado Civil',
    gender: 'Gênero',
    salaryExpectation: 'Expectativa Salarial',
    possibilityChange: 'Possibilidade de mudança',
    attachedFiles: 'Arquivos Anexados'
  },
  workflowCard: {
    create: 'Criar novo fluxo de contratação',
    edit: 'Renomear',
    view: 'Editar',
    noWorkspaces: 'Sem Workspaces',
    tooltip:
      'Você não pode excluir este fluxo de trabalho, pois há vagas vinculadas a ele.',
    modalWorkspacesTitle: 'Workspaces vinculados',
    modalWorkspacesSubtitle:
      'Veja a lista de workspaces vinculados a este workflow'
  },
  jobOpportunity: {
    addJobTitle: 'Adicionar Vaga',
    addJobText: 'Preencha o formulário abaixo para adicionar uma nova vaga',
    editJobTitle: 'Editar Vaga',
    editJobText: 'Editar vaga de ',
    name: 'Nome da vaga',
    roleFamily: 'Cargos relacionados',
    roleFamilyPlaceholder: 'Cargos relacionados (Digite para ver opções)',
    localization: 'Localidade da Vaga',
    amount: 'Quantidade de Vagas',
    deadline: 'Prazo de encerramento',
    vacancy_allocation: 'Alocação da Vaga',
    budget: 'Orçamento da vaga',
    tags: 'Tags da vaga',
    description: 'Descrição da vaga',
    applyJobOpportunity: 'Aplicar para vaga',
    applyCandidate: 'Aplicar candidato!',
    jobNameLabel: 'Nome da Vaga',
    applyToStep: 'Aplicar na Etapa',
    success: 'Candidato aplicado para vaga com sucesso!',
    chooseOne: 'Escolha uma opção',
    noJobFound: 'Nenhuma vaga encontrada'
  },
  recruiterNotes: {
    newNote: 'Escreva os detalhes adicionais aqui...',
    notes: 'Anotações',
    note: 'anotação',
    new: 'nova',
    by: 'por {{name}}',
    review: 'Dê sua nota para esse candidato para a vaga {{jobName}}',
    notAllowed: 'Sem permissão'
  },
  historyContainer: {
    title: 'Histórico',
    description: 'de aplicações do candidato',
    appliedForJob: 'aplicou para a vaga',
    onDate: 'em {{date}}',
    onWorkspace: 'no workspace'
  },
  recruiterAnswers: {
    title: 'Respostas às questões customizadas da vaga'
  },
  job: {
    banner: {
      title: 'Cadastrar Vaga',
      text: ''
    },
    bannerEdit: {
      title: 'Editar vaga',
      text: 'Editar Vaga de '
    },
    updatedMessage: 'Atualizado com sucesso!',
    form: {
      name: 'Nome da Vaga',
      location: 'Localidade da Vaga',
      deadline: 'Prazo para encerramento',
      vacancy_allocation: 'Alocação da Vaga',
      tags: 'Tags da vaga',
      roleFamilyPlaceholder: 'Cargos relacionados (Digite para ver opções)',
      amount: 'Quantidade de vagas',
      budget: 'Orçamento da vaga',
      status: 'Ativar vaga?',
      description: 'Descrição da Vaga'
    },
    linkWorkflow: 'Vincular a um Fluxo de Contratação',
    questions: 'Questões personalizadas para os candidatos',
    questionsInfo:
      'Já existem candidatos que se aplicaram à esta vaga, por isto, as questões não poderão ser alteradas.',
    recruiters: 'Recrutadores disponíveis',
    recruitersTeam: 'Recrutadores do Time',
    managers: 'Gestores disponiveis',
    managersTeam: 'Gestores do Time',
    save: 'Salvar Alterações!',
    creationFailed:
      'Falha ao criar a vaga, preencha os campos obrigatórios e tente novamente',
    creationSuccess: 'Criado com sucesso',
    missingWorkflow:
      'Falha ao criar a vaga, selecione um Fluxo de Contratação e tente novamente',
    updateStatusfalse: 'A vaga foi pausada com sucesso!',
    updateStatustrue: 'A vaga foi retomada com sucesso!',
    modalTitle: 'Confirmação',
    modalTextfalse: 'Deseja pausar a vaga ',
    modalTexttrue: 'Deseja retormar a vaga',
    yes: 'Sim',
    no: 'Não',
    tagError: 'Atingiu o limite máximo de 34 caracteres.'
  },
  jobShare: {
    shareManually: 'Compartilhar manualmente',
    shareWith: 'Compartilhar no {{socialMedia}}',
    message:
      '<p><copy>Clique aqui</copy> para copiar o link de compartilhamento. Após isso, cole onde deseja compartilhar.</p>',
    url: 'Ou use esse endereço: ',
    onCopyingSuccessfully: 'Link copiado com sucesso.'
  },
  workflowStepCard: {
    create: 'Criar nova etapa aqui'
  },
  hiringWorkflow: {
    bannerTitle: 'Fluxos de Contratação',
    bannerText: 'Gerencie os Fluxos de Contratação de sua empresa',
    addNewStage: 'Incluir nova Etapa',
    stageName: 'Nome da etapa',
    stageDescription: 'Descrição da etapa',
    sendEmailAutomatically: 'Enviar email automaticamente?',
    stageEmailContent: 'Conteúdo do email',
    createHiringStage: 'Criar etapa de contratação',
    emailSubject: 'Assunto do e-mail',
    error: {
      description: 'A descrição é um campo obrigatório',
      name: 'O nome é um campo obrigatório'
    }
  },
  listWorkflowsContainer: {
    modalTitle: 'Confirmação',
    modalText:
      'Deseja realmente <strong>deletar</strong> o Fluxo de Contratação',
    successDeleteMessage: 'Fluxo de Contratação removido com sucesso!',
    successCreateMessage: 'Fluxo de Contratação criado com sucesso!',
    successUpdateMessage: 'Fluxo de Contratação atualizado com sucesso!',
    successHiringStageCreateMessage: 'Etapa criada com sucesso!',
    successDuplicateMessage: 'Fluxo de contratação duplicado com sucesso!',
    yes: 'Sim',
    no: 'Não',
    lockedDeletion:
      'Não é possível deletar o Fluxo de Contratação pois ele está associado a uma vaga',
    copy: 'cópia'
  },
  hiringWorkflowEdit: {
    bannerTitle: 'Fluxo de Contratação para',
    bannerText: 'Edite o Fluxo de Contratação',
    firstP:
      'Cadastre aqui o fluxo do processo seletivo da sua vaga. Você pode associar este workflow a uma vaga, e as etapas podem ser utilizadas para filtrar e organizar os candidatos. Lembre-se, com o fluxo de contratação, você saberá em qual etapa cada candidato está no seu processo. Caso tenha dúvida de como cadastrá-lo ',
    button: 'clique aqui'
  },
  editWorkdflowContainer: {
    blocked: 'Etapa padrão, não pode ser removida',
    editEmail: 'Editar e-mail',
    stage: 'ETAPA DO FLUXO DE CONTRATAÇÃO',
    sendEmail: 'ENVIAR E-MAIL AUTOMÁTICO?',
    emailContent: 'CONTEÚDO DO E-MAIL',
    action: 'AÇÃO',
    yes: 'Sim',
    no: 'Não',
    confirm: 'Confirmação',
    deleteContent: 'Deseja realmente <strong>deletar</strong> a etapa ',
    successDeleteMessage: 'Etapa removida com sucesso!',
    editEmailModalTitle: 'Editar email da etapa',
    editEmailModalSubTitle:
      'Este email será enviado quando o candidato for adicionado à',
    editEmailModalSubject: 'Assunto',
    editEmailModalContent: 'Corpo do email',
    createStageTitle: '+ Incluir nova Etapa',
    createStageName: 'Nome da etapa',
    createStageDescription: 'Descrição da etapa',
    createStageSendEmail: 'Enviar email automaticamente?',
    createStageEmailContent: 'Conteúdo do email',
    createStageButton: 'Criar etapa de contratação!',
    successHiringStageCreateMessage: 'Etapa criada com sucesso!',
    successUpdateAutoSend: 'Envio de e-mail automatico atualizado com sucesso!',
    successUpdateEmailContent: 'Conteudo do e-mail atualizado com sucesso!',
    save: 'Salvar Alterações',
    remove: 'Remover',
    emailSubject: 'Assunto do e-mail',
    headerTitle: 'Fluxo de contratação',
    emailContentRequired:
      'Apenas quando o corpo do e-mail estiver adicionado, o recurso será ativado.'
  },
  notFound: {
    button: 'Voltar',
    content:
      'Parece que você fez uma curva errada e encontrou um buraco negro da web que absorveu a página que você estava procurando! Mas não entre em pânico porque você pode voltar !',
    title: 'OOPS!!'
  },
  customQuestions: {
    question: 'Questão',
    eliminate: 'Eliminatória?',
    unique: 'Resposta unica',
    mult: 'Multipla escolha',
    opened: 'Texto livre',
    answers: 'Alternativas',
    rightAnswer: 'Opção correta',
    required: 'Campo obrigatorio!',
    moveUp: 'Mover para cima',
    moveDown: 'Mover para baixo',
    duplicate: 'Duplicar questão',
    delete: 'Deletar questão'
  },
  dashboard: {
    hello: 'Olá',
    seeCompanyData: 'Veja os dados do processo seletivo da empresa:',
    filterByWorkflow: 'Filtrar por Workflow',
    filterByRecruiter: 'Filtrar por Recrutador',
    chart01: 'Vagas abertas',
    chart02: 'Vagas finalizadas',
    chart1: 'Tempo médio de fechamento de vagas',
    chart2: 'Tempo médio para finalizar a vaga por recrutador',
    chart3: 'Fonte de aquisição de candidatos',
    chart4: 'Aplicações de candidatos por dia',
    chart5: 'Candidatos por faixa etária',
    chart6: 'Candidatos por gênero',
    chart7: 'Candidatos por grau de instrução',
    chart8: 'Candidatos por estado civil',
    chart9: 'Candidatos por estado',
    chart10: 'Vagas finalizadas dentro e fora do prazo por recrutador',
    chart11: 'Candidatos por fases nos últimos 6 meses',
    inTime: 'Em prazo',
    late: 'Atrasadas',
    male: 'Masculino',
    female: 'Feminino',
    other: 'Outros',
    errorChart:
      'Este workspace ainda não possui informações suficientes para exibir neste gráfico',
    errorCompanyData: 'Não foi possível carregar os dados da empresa',
    'Solteiro(a)': 'Solteiro(a)',
    'Divorciado(a)': 'Divorciado(a)',
    'Viuvo(a)': 'Viúvo(a)',
    Outro: 'Outro',
    Available: 'Disponível',
    'Casado(a)': 'Casado(a)',
    'Rejected in Triage': 'Rejeitado na Triagem',
    'Approved in Triage': 'Aprovado na Triagem',
    'Denied Offer': 'Rejeitou a oferta',
    'Rejected during the process': 'Rejeitado durante o processo',
    'Solicitar documentos': 'Request Documents',
    'Coleta de documentos': 'Documents Collection',
    'No name': 'name',
    Male: 'Male',
    Female: 'Female',
    'No sex info': 'Sex information',
    'No marital status info': 'Marital status information',
    Single: 'Single',
    Married: 'Married',
    Divorced: 'Divorced',
    Widowed: 'Widowed',
    Feminino: 'Feminino',
    Masculino: 'Masculino',
    'Sem informação de sexo': 'Sem informação de sexo',
    'Sem informações do estado civil': 'Sem informações do estado civil',
    Hired: 'Contratado',
    errorDataChart: 'Falha ao recuperar dados para alguns gráficos',
    errorExpiredSession: 'Sessão expirada',
    Femenino: 'Femenino',
    Otro: 'Otro',
    'Sin información sexual': 'Sin información sexual',
    'Sin información de estado civil': 'Sin información de estado civil',
    'Soltero(a)': 'Soltero(a)',
    'Viudo(a)': 'Viudo(a)',
    Other: 'Other'
  },
  changePassword: {
    changePassword: 'Redefinir Senha',
    password: 'Senha',
    passwordConfirmation: 'Confirmar Senha',
    changePasswordFail:
      'Redefinição falhou. Seu link para redefinição de senha expirou ou está inválido',
    changePasswordSuccess: 'Senha redefinida com sucesso'
  },
  defaultWorkflowStages: {
    defaultStage: 'Esta etapa é padrão e não pode ser removida.',
    goToPosition: 'IR PARA VAGA',
    'Approved in Triage': 'Aprovado na triagem',
    Available: 'Disponível',
    'Failed in the selection process': 'Reprovado no processo de seleção',
    'Failed in the selection processD':
      'O candidato não foi aprovado nas etapas de seleção como entrevista, testes e dinâmicas.',
    'Denied Offer': 'Rejeitou a oferta',
    Hired: 'Contratado',
    'Rejected in Triage': 'Rejeitado na triagem',
    'Approved in TriageD': 'O candidato foi aprovado na triagem.',
    AvailableD:
      'O candidato está interessado em participar do processo seletivo.',
    'Denied OfferD': 'O candidato recusou a proposta ou desistiu do processo.',
    HiredD: 'O candidato foi contratado.',
    'Rejected in TriageD': 'O Candidato foi eliminado do processo seletivo.',
    'Rejected during the process': 'Rejeitado durante o processo',
    'Rejected during the processD':
      'O candidato foi rejeitado durante o processo seletivo.',
    'Approved in process': 'Aprovado no processo',
    'Approved in processD': 'O candidato foi aprovado no processo seletivo.',
    'Solicitar documentos': 'Solicitar documentos',
    'Solicitar documentosD':
      'O candidato recebeu um link para envio dos documentos.',
    'Custom Step do Rafa': 'Custom Step do Rafa'
  },
  codeConfirmationModal: {
    title: 'Confirmar novo email',
    subtitle: 'Digite o código que você recebeu em seu novo e-mail',
    label: 'Código',
    buttonText: 'Confirmar código'
  },
  passwordConfirmationModal: {
    title: 'Mudança de email',
    subtitle: 'Para mudar seu e-mail por favor confirme a senha',
    label: 'Senha',
    buttonText: 'Confirmar senha'
  },
  candidatesListContainer: {
    notfound: 'Nenhum registro encontrado.',
    select: 'Selecione o perfil de contratação',
    title: 'Escolha o perfil de contratação',
    button: 'Concluir',
    info: 'Agora, selecione o perfil de contratação para definir a documentação que o colaborador deverá enviar.',
    infoBold:
      'Ao clicar em “concluir”, o link de admissão será enviado para o candidato.',
    alertInfo: 'Não encontrou o',
    alertInfoBold: '“Perfil de Contratação”',
    alertInfo2:
      'Solicite a criação ao time responsável ou acesse o Lugarh para cadastrar.',
    noResults: {
      title: 'Esta vaga ainda não possui candidatos.',
      subtitle:
        'Enquanto aguarda as candidaturas, você pode buscar o perfil ideal na sua base geral de candidatos ou adicionar um novo currículo manualmente.',
      titleStage:
        'Ainda não possuem candidatos nesta etapa do processo seletivo.',
      subtitleStage:
        'Para incluir um candidato nesta etapa, basta movê-lo para cá.',
      addJob: 'adicionar manualmente',
      changeWorkspace: 'ver candidatos da base '
    }
  },
  jobsContainer: {
    notfound: 'Nenhum registro encontrado.'
  },
  searchBox: {
    loading: 'Carregando...',
    notFound: 'Nenhum resultado encontrado'
  },
  subscriptionSettings: {
    headerSettigns: {
      headerTitle: 'Configurações de Assinatura',
      headerText: 'Atualizar ou cancelar assinatura'
    },
    nextChargeTitle: 'Próxima Cobrança',
    nextChargeDescriptionDate: 'Sua próxima cobrança ocorrerá em ',
    nextChargeDescriptionValue: 'no valor total de ',
    paymentMethodTitle: 'Método de Pagamento',
    paymentMethodType: 'Cartão de Crédito',
    paymentMethodCardNumber: 'com final de número ',
    paymentMethodCardNumberExpiresAt: 'Valido até: ',
    warningWithoutPaymentMethod:
      'Não existe um método de pagamento cadastrado.',
    descriptionWithoutPaymentMethod:
      'Você pode adicionar um método de pagamento quando for pagar a próxima fatura e habilitá-lo para pagamentos futuros.',
    invoices: {
      invoicesTitle: 'Faturas',
      expiresAt: 'Vencimento',
      value: 'Valor',
      status: 'Status',
      link: 'Visualizar',
      statusPaid: 'Pago',
      statusPending: 'Pendente',
      statusExpired: 'Expirada',
      statusCanceled: 'Cancelada',
      statusRefunded: 'Reembolsada'
    },
    unsubscribeEmail: {
      subject: 'Solicita%C3%A7%C3%A3o%20de%20Cancelamento',
      body: 'Ol%C3%A1%2C%0A%0AGostaria%20por%20favor%20de%20solicitar%20o%20cancelamento%20para%20as%20pr%C3%B3ximas%20faturas%20da%20plataforma%20de%20Recrutamento%20e%20Sele%C3%A7%C3%A3o%20Recrud.',
      buttonLabel: 'Cancelar assinatura'
    }
  },
  candidateInfo: {
    years: 'anos'
  },
  badgeList: {
    initialScale: 'Não aderente',
    finalScale: 'Totalmente aderente',
    candidateAssessment:
      'A avaliação final em estrelas é calculada como a média das avaliações fornecidas por todos os usuários, variando de não aderente a excelente. Essa avaliação reflete a avaliação geral do candidato.'
  },
  recruiterList: {
    managers: 'Gestores',
    recruiters: 'Recrutadores'
  },
  workspaces: {
    lugarh: {
      title: 'Workspaces',
      subtitle: 'Adicione, edite ou remova os workspaces da sua empresa.',
      geralWorkspaceTooltip: 'Este é um workspace padrão do sistema.',
      button: {
        create: 'Criar novo workspace'
      },
      table: {
        actions: 'Ações',
        soon: 'Em breve',
        noCnpj: 'Não possui CNPJ cadastrado',
        modal: {
          title: 'Usuários vinculados',
          subtitle: 'Veja a lista de usuários vinculadas a este workspace'
        },
        head: {
          workspaces: 'Workspaces',
          users: 'Usuários',
          cnpj: 'CNPJ'
        },
        buttons: {
          edit: 'Editar',
          exclude: 'Excluir'
        }
      }
    }
  },
  resumeApplyCandidate: {
    description: 'Insira as informações para aplicar o candidato a uma vaga:',
    defaultOption: 'Selecione uma opção',
    loading: 'carregando...',
    sending: 'enviando...',
    workspace: {
      label: 'Workspace',
      no_workspace: 'Nenhum workspace encontrado',
      observation: 'Por favor, Tente novamente.'
    },
    position: {
      label: 'Nome da vaga',
      no_position: 'Nenhuma vaga encontrada',
      observation: 'Por favor, tente outro workspace.'
    },
    step: {
      name: 'Etapa',
      label: 'Aplicar na etapa',
      no_step: 'Nenhuma etapa encontrada.',
      observation: 'Por favor, tente outra vaga.'
    },
    messages: {
      success: 'Candidato aplicado com sucesso!',
      error: 'Erro ao aplicar candidato.',
      error_observation: 'Por favor, tente novamente ou contate o suporte'
    },
    button: {
      cancel: 'Cancelar',
      apply: 'Aplicar candidato'
    }
  }
}

export default ptBR

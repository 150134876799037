/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react'

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import classNames from 'classnames'
import orderBy from 'lodash/orderBy'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-icon.svg'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { Modal } from 'components/Modal/Modal'
import { NewHiringStageModal } from 'components/NewHiringStageModal/NewHiringStageModal'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'
import { Switch } from 'components/Switch/Switch'
import { WorkflowStep } from 'components/WorkflowStep/WorkflowStep'
import { workflow } from 'services/api'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import ReactGA from 'utils/analytics'
import { isDefaultStage } from 'utils/defaultStage'

import './EditWorkflowsContainer.scss'
import Tooltip from '@mui/material/Tooltip'

export type EditWorkflowsRefs = {
  defaultStage: React.MutableRefObject<HTMLElement>
  createStage: React.MutableRefObject<HTMLElement>
  customStage: React.MutableRefObject<HTMLElement>
  deleteStage: React.MutableRefObject<HTMLElement>
  sendEmail: React.MutableRefObject<HTMLElement>
  editEmail: React.MutableRefObject<HTMLElement>
}

type Props = {
  getRefs: (value) => void
}

export const EditWorkflowsContainer: React.FC<Props> = ({ getRefs }) => {
  const { t } = useTranslation('editWorkdflowContainer')
  const [tworkflows] = useTranslation('defaultWorkflowStages')
  const { uuid } = useParams<{ uuid: string }>()
  const { dispatch } = useStore()
  const [workflows, setWorkflows] = useState<WorkflowStageSchema[]>()
  const [confirmation, setConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [createStageModal, setCreateStageModal] = useState(false)
  const [order, setOrder] = useState<number>(4)
  const [emailContentModal, setEmailContentModal] = useState(false)
  const [emailSubject, setEmailSubject] = useState('')
  const [currentDelete, setCurrentDelete] = useState<WorkflowStageSchema>(
    {} as WorkflowStageSchema
  )
  const [currentEdit, setCurrentEdit] = useState<WorkflowStageSchema>(
    {} as WorkflowStageSchema
  )
  const [emailContent, setEmailContent] = useState('')

  const defaultStage = useRef<HTMLDivElement>(null)
  const createStage = useRef<HTMLDivElement>(null)
  const customStage = useRef<HTMLDivElement>(null)
  const deleteStage = useRef<HTMLDivElement>(null)
  const sendEmail = useRef<HTMLDivElement>(null)
  const editEmail = useRef<HTMLDivElement>(null)

  useEffect(() => {
    fetchWorkflows()
  }, [])

  useEffect(() => {
    const obj = {
      defaultStage,
      createStage,
      customStage,
      deleteStage,
      sendEmail,
      editEmail
    }

    getRefs(obj)
  }, [
    defaultStage,
    createStage,
    customStage,
    deleteStage,
    sendEmail,
    editEmail
  ])

  useEffect(() => {
    setEmailContent(currentEdit.email_content)
    setEmailSubject(currentEdit.email_subject)
  }, [currentEdit])

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const fetchWorkflows = async () => {
    try {
      const [error, data] = await workflow.getStages({
        workflow_uuid: uuid,
        per_page: 100,
        page: 1,
        job_uuid: ''
      })
      if (error) throw new Error(error.toString())
      const orderedWorkflows = orderBy(data?.data, ['order'], ['asc'])
      setWorkflows(orderedWorkflows)
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const removeStage = async () => {
    try {
      const [error] = await workflow.removeStage(uuid, currentDelete.uuid)
      if (error) throw new Error(error.toString())
      setCurrentDelete({} as WorkflowStageSchema)
      fetchWorkflows()
      showNotification({
        message: t('successDeleteMessage'),
        type: 'success'
      })
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const updateAutoSend = async (
    autoSend: boolean,
    emailContent: string,
    uuidStage: string,
    type: 'autoSend' | 'emailContent',
    stage: WorkflowStageSchema
  ) => {
    try {
      setIsLoading(true)
      const [error] = await workflow.updateAutoSendAndEmailContent(
        type === 'autoSend'
          ? {
              email_autosend: autoSend,
              uuid: uuidStage
            }
          : {
              email_content: emailContent,
              email_subject: emailSubject,
              uuid: uuidStage
            },
        uuid
      )

      if (error) {
        const errorOBJ = error.valueOf() as {
          message: string
          errors: {
            [key: string]: string[]
          }
        }
        setIsLoading(false)
        return showNotification({ message: errorOBJ.message, type: 'error' })
      }

      fetchWorkflows()
      setCurrentEdit({} as WorkflowStageSchema)
      setEmailContent('')
      setEmailSubject('')
      setEmailContentModal(false)
      setIsLoading(false)
      showNotification({
        message:
          type === 'autoSend'
            ? t('successUpdateAutoSend')
            : t('successUpdateEmailContent'),
        type: 'success'
      })

      if (isDefaultStage(stage) && type === 'emailContent') {
        const stageName = stage.name.toLowerCase().replaceAll(' ', '_')

        ReactGA.event(`edit_email_to_${stageName}_finish`, {
          event_category: 'workflow',
          event_label: `edit_email_to_${stageName}_finish`
        })
      }
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
      setIsLoading(false)
    }
  }

  const createNewHiringState = async (
    payload: CreateNewHiringStatePayload
  ): Promise<void> => {
    setIsLoading(true)

    try {
      const [error] = await workflow.createHiringStage(payload, uuid)
      if (error) throw new Error(error.toString())

      fetchWorkflows()
      setCreateStageModal(false)
      showNotification({
        message: t('successHiringStageCreateMessage'),
        type: 'success'
      })
    } catch (error: unknown) {
      const errorMessage = (error as Error).message
      showNotification({ message: errorMessage, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const handleEditWorkflowEmail = stage => () => {
    setEmailContentModal(true)
    setCurrentEdit(stage)

    if (isDefaultStage(stage)) {
      const name = stage.name.toLowerCase().replaceAll(' ', '_')

      ReactGA.event(`edit_email_to_${name}_start`, {
        event_category: 'workflow',
        event_label: `edit_email_to_${name}_start`
      })
    }
  }

  const handleCreateNewStep = (index: number) => () => {
    setCreateStageModal(true)
    setOrder(index + 2)

    ReactGA.event('create_new_step', {
      event_category: 'workflow',
      event_label: 'create_new_step'
    })
  }

  return (
    <>
      <div className='list section-header-info'>
        <div className='section-header-info__container'>
          <h2 className='section-title'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='25'
              viewBox='0 0 27 27'
              fill='none'
            >
              <path
                d='M20.7874 14.1792C20.8305 13.8559 20.8521 13.522 20.8521 13.1664C20.8521 12.8217 20.8305 12.4769 20.7767 12.1537L22.9637 10.4514C23.1577 10.3006 23.2115 10.0097 23.093 9.79423L21.0245 6.21734C20.8952 5.98032 20.6258 5.9049 20.3888 5.98032L17.8139 7.0146C17.2752 6.6052 16.7042 6.26044 16.0685 6.00187L15.6807 3.26533C15.6376 3.00676 15.4221 2.82361 15.1635 2.82361H11.0264C10.7678 2.82361 10.5631 3.00676 10.52 3.26533L10.1322 6.00187C9.49653 6.26044 8.91474 6.61597 8.38683 7.0146L5.8119 5.98032C5.57488 5.89413 5.30553 5.98032 5.17625 6.21734L3.11846 9.79423C2.98917 10.0205 3.03227 10.3006 3.24774 10.4514L5.43482 12.1537C5.38095 12.4769 5.33785 12.8324 5.33785 13.1664C5.33785 13.5004 5.3594 13.8559 5.41327 14.1792L3.2262 15.8814C3.03227 16.0322 2.9784 16.3231 3.09691 16.5386L5.16547 20.1155C5.29476 20.3525 5.5641 20.4279 5.80113 20.3525L8.37605 19.3182C8.91474 19.7276 9.48575 20.0724 10.1214 20.331L10.5093 23.0675C10.5631 23.3261 10.7678 23.5092 11.0264 23.5092H15.1635C15.4221 23.5092 15.6376 23.3261 15.6699 23.0675L16.0577 20.331C16.6934 20.0724 17.2752 19.7276 17.8031 19.3182L20.378 20.3525C20.615 20.4387 20.8844 20.3525 21.0137 20.1155L23.0822 16.5386C23.2115 16.3016 23.1577 16.0322 22.953 15.8814L20.7874 14.1792ZM13.095 17.045C10.9618 17.045 9.21641 15.2996 9.21641 13.1664C9.21641 11.0332 10.9618 9.28786 13.095 9.28786C15.2282 9.28786 16.9735 11.0332 16.9735 13.1664C16.9735 15.2996 15.2282 17.045 13.095 17.045Z'
                fill='currentColor'
              />
            </svg>
            {t('headerTitle')}
          </h2>
        </div>
      </div>
      <div className='modal-add'>
        <NewHiringStageModal
          open={createStageModal}
          order={order}
          onSubmit={createNewHiringState}
          closeModal={setCreateStageModal}
          isLoading={isLoading}
        />
      </div>

      <div className='modal-confirmation'>
        <Modal
          title={t('confirm')}
          open={confirmation}
          onClose={() => setConfirmation(false)}
        >
          <div className='modal-confirmation-content'>
            <p className='content'>
              <span dangerouslySetInnerHTML={{ __html: t('deleteContent') }} />
              <strong>{currentDelete.name}</strong>
            </p>

            <div className='row'>
              <div className='col-6'>
                <Button
                  className='secondary'
                  onClick={() => {
                    removeStage()
                    setConfirmation(false)
                  }}
                >
                  {t('yes')}
                </Button>
              </div>

              <div className='col-6'>
                <Button
                  className='danger'
                  onClick={() => {
                    setCurrentDelete({} as WorkflowStageSchema)
                    setConfirmation(false)
                  }}
                >
                  {t('no')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className='modal-email'>
        <Modal
          title={`${t('editEmailModalTitle')} ${tworkflows(currentEdit.name)}`}
          open={emailContentModal}
          onClose={() => setEmailContentModal(false)}
        >
          <div className='modal-email-content'>
            <p>
              {t('editEmailModalSubTitle')} {tworkflows(currentEdit.name)}
            </p>

            <Input
              onChange={setEmailSubject}
              value={emailSubject}
              name='emailsubject'
              type='text'
              label={t('emailSubject')}
            />

            <RichTextEditor
              height={200}
              value={emailContent}
              onChange={setEmailContent}
              placeholder={t('editEmailModalContent')}
            />

            <Button
              className='secondary mt-3 mb-3'
              disabled={
                isLoading || !(emailSubject?.trim() || emailContent?.trim())
              }
              onClick={() => {
                updateAutoSend(
                  currentEdit.email_autosend,
                  emailContent,
                  currentEdit.uuid,
                  'emailContent',
                  currentEdit
                )
              }}
            >
              {t('save')}
            </Button>
          </div>
        </Modal>
      </div>

      <div className='widget-box'>
        <div className='d-none d-sm-none d-md-block'>
          <div className='row bb'>
            <div className='col-12 col-md-6 d-flex align-items-center'>
              <p>{t('stage')}</p>
            </div>
            <div className='col-4 col-md-2 d-flex align-items-center justify-content-center'>
              <p style={{ textAlign: 'center' }}>{t('sendEmail')}</p>
            </div>
            <div className='col-4 col-md-3 d-flex align-items-center justify-content-center'>
              <p>{t('emailContent')}</p>
            </div>
            <div className='col-4 col-md-1 d-flex align-items-center justify-content-center'>
              <p>{t('action')}</p>
            </div>
          </div>
        </div>

        {workflows?.map((el, index) => {
          return (
            <div key={el.uuid}>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-12 col-md-6'>
                  <div ref={!el.deletable ? defaultStage : customStage}>
                    <WorkflowStep
                      title={tworkflows(el.name)}
                      description={
                        el.deletable
                          ? el.description
                          : tworkflows(`${el.name}D`)
                      }
                      step={index + 1}
                      kind={!el.deletable ? 'default' : 'custom'}
                    />
                  </div>
                </div>

                <div className='col-12 col-md-2 d-flex align-items-center justify-content-center mb-sm-3'>
                  <div
                    ref={sendEmail}
                    className='align-items-center justify-content-center mb-sm-3'
                  >
                    <label htmlFor='switch_container' className={'d-md-none'}>
                      {t('sendEmail')}
                    </label>

                    {el.email_content ? (
                      <Switch
                        initialValue={el.email_autosend}
                        onChange={value =>
                          updateAutoSend(
                            value,
                            el.email_content,
                            el.uuid,
                            'autoSend',
                            el
                          )
                        }
                        disabled={false}
                      />
                    ) : (
                      <Tooltip title={t('emailContentRequired')}>
                        <div>
                          <Switch
                            initialValue={el.email_autosend}
                            onChange={value =>
                              updateAutoSend(
                                value,
                                el.email_content,
                                el.uuid,
                                'autoSend',
                                el
                              )
                            }
                            disabled={true}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div
                  ref={editEmail}
                  className='col-5 col-md-3 d-flex align-items-center justify-content-center'
                >
                  <Button
                    className='primary-custom'
                    onClick={handleEditWorkflowEmail(el)}
                  >
                    {t('editEmail')}
                  </Button>
                </div>
                <div
                  ref={deleteStage}
                  className='col-3 col-md-1 d-flex align-items-center justify-content-center'
                >
                  {el.deletable ? (
                    <Button
                      className={classNames('btn-delete')}
                      {...(el.deletable
                        ? { 'data-tooltip': t('remove') }
                        : null)}
                      onClick={() => {
                        if (el.deletable) {
                          setConfirmation(true)
                          setCurrentDelete(el)
                        }
                      }}
                    >
                      <TrashIcon color='#1976D2' />
                    </Button>
                  ) : (
                    <div>
                      <Button
                        className={classNames('btn-blocked')}
                        {...{ 'data-tooltip': t('blocked') }}
                        disabled
                      >
                        <RemoveCircleIcon />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {(index === 3 || el.deletable) && (
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div ref={createStage}>
                      <WorkflowStep
                        kind='add'
                        onCreate={handleCreateNewStep(index)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}

import { Theme } from 'context/ThemeContext/ThemeContext.config'

import * as Pages from '../pages'

export enum RoutePaths {
  login = '/login',
  signup = '/signup',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  content = '/content/:section',
  plans = '/plans',
  checkout = '/checkout/:plan',
  companySettings = '/company-settings',
  jobs = '/jobs',
  job = '/job',
  jobApplications = '/job/:uuid/applications',
  editJob = '/edit-job/:uuid',
  hiringWorkflow = '/hiring-workflow',
  hiringWorkflowEdit = '/hiring-workflow-edit/:name/:uuid',
  candidatesList = '/candidates-list/:uuid',
  candidatesDatabase = '/candidates-database',
  candidateDetail = '/candidate-detail/:uuid',
  candidatePage = '/candidate/:uuid',
  addCandidate = '/add-candidate',
  jobOpportunity = '/job-opportunity',
  teamManagement = '/team-management',
  dashboard = '/dashboard',
  Settings = '/Settings',
  myProfile = '/my-profile',
  subscriptionSettings = '/subscription-settings',
  workspaces = '/workspaces',
  notAllowed = '/not-allowed',
  reusableComponents = '/ui-elements/reusable-components'
}

export const routesConfig: SetPageDetailsParams[] = [
  {
    path: RoutePaths.login,
    private: false,
    theme: Theme.LUGARH,
    pageFolder: 'login',
    recrudPage: Pages.LoginPage
  },
  {
    path: RoutePaths.signup,
    private: false,
    theme: Theme.LUGARH,
    pageFolder: 'signup',
    recrudPage: Pages.LoginPage
  },
  {
    path: RoutePaths.forgotPassword,
    private: false,
    theme: Theme.LUGARH,
    pageFolder: 'forgotPassword',
    recrudPage: Pages.ForgotPasswordPage
  },
  {
    path: RoutePaths.resetPassword,
    private: false,
    theme: Theme.LUGARH,
    pageFolder: 'resetPassword',
    recrudPage: Pages.ResetPasswordPage
  },
  {
    path: RoutePaths.content,
    private: false,
    theme: Theme.RECRUD,
    pageFolder: 'content',
    recrudPage: Pages.ContentPage
  },
  {
    path: RoutePaths.plans,
    private: false,
    theme: Theme.LUGARH,
    pageFolder: 'plans',
    recrudPage: Pages.PlansPage
  },
  {
    path: RoutePaths.checkout,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'checkoutPlan',
    recrudPage: Pages.CheckoutPlanPage
  },
  {
    path: RoutePaths.companySettings,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'companySettings',
    recrudPage: Pages.CompanySettingsPage
  },
  {
    path: RoutePaths.jobs,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'jobs',
    recrudPage: Pages.JobsPage
  },
  {
    path: RoutePaths.job,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'jobs',
    recrudPage: Pages.CreateJobsPage
  },
  {
    path: RoutePaths.jobApplications,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'candidatesList',
    recrudPage: Pages.CandidatesListPage
  },
  {
    path: RoutePaths.editJob,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'jobs',
    recrudPage: Pages.EditJobsPage
  },
  {
    path: RoutePaths.hiringWorkflow,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'hiringWorkflow',
    recrudPage: Pages.HiringWorkflow
  },
  {
    path: RoutePaths.hiringWorkflowEdit,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'hiringWorkflowEdit',
    recrudPage: Pages.HiringWorkflowEdit
  },
  {
    path: RoutePaths.candidatesList,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'candidatesList',
    recrudPage: Pages.CandidatesListPage
  },
  {
    path: RoutePaths.candidatesDatabase,
    private: true,
    theme: Theme.LUGARH,
    pageFolder: 'candidatesDatabase'
    // recrudPage: Pages.CandidatesDatabasePage
  },
  {
    path: RoutePaths.candidateDetail,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'candidateDetail',
    recrudPage: Pages.CandidateDetail
  },
  {
    path: RoutePaths.candidatePage,
    private: true,
    theme: Theme.LUGARH,
    pageFolder: 'candidate'
  },
  {
    path: RoutePaths.jobOpportunity,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'jobOpportunity',
    recrudPage: Pages.JobOpportunityFormPage
  },
  {
    path: RoutePaths.teamManagement,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'teamManagement',
    recrudPage: Pages.TeamManagementPage
  },
  {
    path: RoutePaths.dashboard,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'dashboard',
    recrudPage: Pages.Dashboard
  },
  {
    path: RoutePaths.Settings,
    private: true,
    theme: Theme.LUGARH,
    pageFolder: 'Settings'
  },
  {
    path: RoutePaths.myProfile,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'myProfile',
    recrudPage: Pages.MyProfilePage
  },
  {
    path: RoutePaths.subscriptionSettings,
    private: true,
    theme: Theme.RECRUD,
    pageFolder: 'subscriptionSettings',
    recrudPage: Pages.SubscriptionSettingsPage
  },
  {
    path: RoutePaths.addCandidate,
    private: true,
    theme: Theme.LUGARH,
    pageFolder: 'addCandidate'
    // recrudPage: Pages.AddCandidatePage
  },
  {
    path: RoutePaths.workspaces,
    private: true,
    theme: Theme.LUGARH,
    pageFolder: 'workspaces'
  },
  {
    path: RoutePaths.notAllowed,
    private: true,
    theme: Theme.LUGARH,
    pageFolder: 'notAllowed'
  },
  {
    path: RoutePaths.reusableComponents,
    private: false,
    theme: Theme.LUGARH,
    pageFolder: 'reusableComponents'
  }
]

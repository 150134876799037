/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { Navigate, RouteProps } from 'react-router-dom'

import { Header } from 'components/Header/Header'
import { useCompany } from 'hooks/useCompany'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import * as permissionsActions from 'store/permissions/actions'

export const PrivateRoute: React.FC<
  RouteProps & { component: React.ElementType }
> = ({ component: Component, path: pathname }) => {
  const company = useCompany()
  const { dispatch, selectors } = useStore()
  const userLogged = selectors.auth.logged()
  const hasCompany = selectors.auth.hasCompany()
  const keepConnected = selectors.auth.keepConnected()
  const refreshToken = selectors.auth.refreshToken()
  const expiresIn = selectors.auth.expiresIn() || 250
  const permissions = selectors.permissions.permissions()

  useEffect(() => {
    if (userLogged && Object.keys(permissions).length === 0) {
      dispatch(permissionsActions.getUserPermissions())
    }
  }, [])

  const handleRefreshToken = () => {
    if (keepConnected && refreshToken) {
      return dispatch(authActions.refreshToken(refreshToken as string))
    }
  }

  useEffect(() => {
    const interval = setInterval(
      () => handleRefreshToken(),
      (expiresIn - 30) * 1000
    )
    return () => {
      clearInterval(interval)
    }
  }, [refreshToken])

  const notReceivedCompanyStatus =
    company?.activeCompany === null || company?.activeCompany === undefined
  const companyIsActive = company?.activeCompany

  if (userLogged && !companyIsActive) {
    if (notReceivedCompanyStatus) return

    if (
      pathname === '/checkout/plan-basic' ||
      pathname === '/checkout/plan-smart' ||
      pathname === '/checkout/plan-advanced' ||
      pathname === '/checkout/plan-enterprise' ||
      pathname === '/plans'
    ) {
      return <Component />
    } else if (pathname !== '/plans') {
      return company?.inactiveCompany()
    }
  }

  if (userLogged) {
    return (
      <>
        <Header />
        <Component />
      </>
    )
  } else {
    return companyIsActive || notReceivedCompanyStatus ? (
      <Navigate to='/' />
    ) : (
      <Navigate to='/plans' />
    )
  }
}

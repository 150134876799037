/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { company } from 'services/api'
import useStore from 'store'
import * as authActions from 'store/auth/actions'
import { CheckCallbackUrl } from 'utils/partnerCallbackUrl'

export function useCompany(): CompanyPayloadHook | null {
  const { dispatch, selectors } = useStore()
  const navigate = useNavigate()
  const companyId = selectors.auth.companyId()
  const [companyData, setCompanyData] = useState<CompanyPayloadResult | null>(
    null
  )
  const [activeCompany, setActiveCompany] = useState<boolean | null>(null)

  const companyStatus = () => {
    if (companyData?.data === undefined) return
    if (companyData?.error) return

    if (!companyData?.data || companyData.data?.status === 'false') {
      return setActiveCompany(companyData?.data?.status === 'false')
    }

    if (companyData?.data)
      return setActiveCompany(companyData.data.status === 'active')

    setActiveCompany(null)
  }

  useEffect(() => {
    if (!companyId) return
    company.get(companyId).then(response => {
      const [error, data] = response

      if (error)
        return setCompanyData({
          data: null,
          error: true
        })

      setCompanyData({
        data,
        error: false
      })
    })
  }, [companyId])

  useEffect(() => {
    companyStatus()
  }, [companyData])

  const inactiveCompany = () => {
    selectors.auth.clearUser()

    dispatch(authActions.logoff())

    if (CheckCallbackUrl()) {
      window.localStorage.setItem('removeCallbackUrl', 'true')
    } else {
      navigate('/plans')
      window.location.reload()
    }
  }

  return {
    companyData,
    activeCompany,
    inactiveCompany
  }
}

import React from 'react'

import { useTranslation } from 'react-i18next'
import './ErrorCharts.scss'

import ErrorChart from '../../assets/images/background/error-chart.png'
export const ErrorCharts: React.FC = () => {
  const { t } = useTranslation('dashboard')
  return (
    <div className='theme-lugarh'>
      <div className='error-charts'>
        <img src={ErrorChart} alt='Error Image' />
        <p className='error-charts__text-error'>{t('errorChart')}</p>
      </div>
    </div>
  )
}

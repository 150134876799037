/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as PlusIcon } from 'assets/svg/plus-white.svg'
import { Button } from 'components/Button/Button'
import {
  ThemeButton,
  ThemeButtonTemplate
} from 'components/Button/lugarh/ThemeButton'
import { JobCardRefs } from 'components/JobCard/JobCard'
import { JobList } from 'components/JobList/JobList'
import { Modal } from 'components/Modal/Modal'
import { DuplicateWorkspace } from 'components/Modals/lugarh/DuplicateWorkspace/DuplicateWorkspace'
import { MoveWorkspace } from 'components/Modals/lugarh/MoveWorkspace/MoveWorkspace'
import { ObjectError } from 'containers/Jobs/EditContainer/EditContainer'
import { jobs as jobsAPI } from 'services/api/'
import useStore from 'store'
import * as notificationActions from 'store/notification/actions'
import ReactGA from 'utils/analytics'
import userCheckPermission from 'utils/userCheckPermission'

import './JobListCards.scss'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

type ModalOpen = {
  opened: boolean
  uuid: string
}

type JobListCardsProps = {
  jobs: Job[]
  setModalOpen: React.Dispatch<React.SetStateAction<ModalOpen>>
  setRefs: React.Dispatch<React.SetStateAction<JobCardRefs>>
  type: 'grid' | 'list'
  onUpdate: () => void
}

export const JobListCards: React.FC<JobListCardsProps> = ({
  jobs,
  setModalOpen,
  setRefs,
  type,
  onUpdate
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const navigate = useNavigate()
  const { dispatch } = useStore()
  const { t } = useTranslation('job')
  const { t: jobCardT } = useTranslation('jobCard')
  const [currentJob, setCurrentJob] = useState<Job>({} as Job)
  const [confirmation, setConfirmation] = useState(false)
  const [openMoveModal, setOpenMoveModal] = useState<boolean>(false)
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false)
  const [selectedJob, setSelectedJob] = useState<string>('')

  const showNotification = (payload: NotificationConfig) =>
    dispatch(notificationActions.notify(payload))

  const toggleJobActive = async (job: Job) => {
    try {
      const payload = { active: !job.active }
      const [error] = await jobsAPI.update(payload, job.uuid)
      if (error === 'This feature has already reached the limit.') {
        return showNotification({
          message: t('errors:limitReached'),
          type: 'error'
        })
      }

      if (error) {
        return showNotification({
          message: (error as ObjectError).message,
          type: 'error'
        })
      }

      showNotification({
        message: t('updateStatus' + payload.active),
        type: 'success'
      })

      onUpdate()
    } catch (error) {
      showNotification({ message: error.message, type: 'error' })
    }
  }

  const pauseAndEnableJob = (job: React.SetStateAction<Job>) => () => {
    setCurrentJob(job)
    setConfirmation(true)

    ReactGA.event('pause_and_enable_job_start', {
      event_category: 'job_listing',
      event_label: 'pause_and_enable_job_start'
    })
  }

  const confirmPauseAndEnableJob = () => {
    toggleJobActive(currentJob)
    setConfirmation(false)

    ReactGA.event('pause_and_enable_job_finish', {
      event_category: 'job_listing',
      event_label: 'pause_and_enable_job_finish'
    })
  }

  const shareJob = (job: Job) => () => {
    setModalOpen({ opened: true, uuid: job.uuid })

    ReactGA.event('share_job_start', {
      event_category: 'job_listing',
      event_label: 'share_job_start'
    })
  }

  const editJob = (job: Job) => () => {
    navigate(`/edit-job/${job.uuid}`)

    ReactGA.event('edit_job_start', {
      event_category: 'job_listing',
      event_label: 'edit_job_start'
    })
  }

  const moveJob = (jobUuid: string) => () => {
    setSelectedJob(jobUuid)
    setOpenMoveModal(true)
  }

  const duplicateJob = (jobUuid: string) => () => {
    setSelectedJob(jobUuid)
    setOpenDuplicateModal(true)
  }

  return (
    <>
      <div className='modal-confirmation' style={{ position: 'absolute' }}>
        <Modal
          title={t('modalTitle')}
          open={confirmation}
          onClose={() => setConfirmation(false)}
        >
          <div className='modal-confirmation-content'>
            <p className='content'>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('modalText' + !currentJob.active)
                }}
              />
              <strong> {currentJob.name}</strong>
            </p>

            <div className='row'>
              <div className='col-6'>
                <Button
                  className='secondary'
                  onClick={confirmPauseAndEnableJob}
                >
                  {t('yes')}
                </Button>
              </div>

              <div className='col-6'>
                <Button
                  className='danger'
                  onClick={() => {
                    setCurrentJob({} as Job)
                    setConfirmation(false)
                  }}
                >
                  {t('no')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {dontShowOnGeralWorkspace && (
        <div
          className={`d-flex offset-9 justify-content-center align-items-center `}
          style={{ gridArea: 'action' }}
        >
          <ThemeButton
            title={jobCardT('createNewVacancy')}
            template={ThemeButtonTemplate.SECONDARY}
            startIcon={<PlusIcon />}
            customClass='joblist__button'
            onClick={() => navigate('/job')}
          />
        </div>
      )}
      {jobs.map(job => {
        return (
          <div className='mt-3' style={{ overflow: 'visible' }} key={job.uuid}>
            <JobList
              hiddenActiveStatus={userCheckPermission({
                resource: 'job',
                scope: ['filter:job-status']
              })}
              status={job.active ? 'active' : 'disabled'}
              title={job.name}
              workspace={job.workspace}
              location={job.city}
              team={[
                // @ts-ignore
                ...[...job.recruitersTeam].map(recruiter => {
                  return { ...recruiter, type: 'recruiter' }
                }),
                // @ts-ignore
                ...[...job.managersTeam].map(recruiter => {
                  return { ...recruiter, type: 'managers' }
                })
              ]}
              hiddenTeam={userCheckPermission({
                resource: 'job',
                scope: ['filter:user-allocated']
              })}
              hiddenEdit={userCheckPermission({
                resource: 'job',
                scope: ['filter:user-edit']
              })}
              tags={job.tags}
              onShare={shareJob(job)}
              onEdit={editJob(job)}
              onMove={moveJob(job.uuid)}
              onDuplicate={duplicateJob(job.uuid)}
              onListCandidates={() => navigate(`/candidates-list/${job.uuid}/`)}
              getRefs={refs => setRefs({ ...refs })}
              toggleActive={pauseAndEnableJob(job)}
            />
          </div>
        )

        // return (
        //   <JobCard
        //     key={job.uuid}
        //     hiddenActiveStatus={userCheckPermission({
        //       resource: 'job',
        //       scope: ['filter:job-status']
        //     })}
        //     status={job.active ? 'active' : 'disabled'}
        //     overdue={job.deadline < dateToTimestamp(Date.now())}
        //     closed={job.closed}
        //     title={job.name}
        //     location={job.city}
        //     team={[
        //       // @ts-ignore
        //       ...[...job.recruitersTeam].map(recruiter => {
        //         return { ...recruiter, type: 'recruiter' }
        //       }),
        //       // @ts-ignore
        //       ...[...job.managersTeam].map(recruiter => {
        //         return { ...recruiter, type: 'managers' }
        //       })
        //     ]}
        //     hiddenTeam={userCheckPermission({
        //       resource: 'job',
        //       scope: ['filter:user-allocated']
        //     })}
        //     hiddenEdit={userCheckPermission({
        //       resource: 'job',
        //       scope: ['filter:user-edit']
        //     })}
        //     tags={job.tags}
        //     onShare={shareJob(job)}
        //     onEdit={editJob(job)}
        //     onListCandidates={() => navigate(`/candidates-list/${job.uuid}/`)}
        //     getRefs={refs => setRefs({ ...refs })}
        //     toggleActive={pauseAndEnableJob(job)}
        //   />
        // )
      })}

      <MoveWorkspace
        open={openMoveModal}
        onClose={() => {
          setOpenMoveModal(false)
          navigate(0)
        }}
        customClass='ws-modal'
        jobUuid={selectedJob}
      />
      <DuplicateWorkspace
        open={openDuplicateModal}
        onClose={() => {
          setOpenDuplicateModal(false)
          navigate(0)
        }}
        customClass='ws-modal'
        jobUuid={selectedJob}
      />
    </>
  )
}

import React from 'react'

import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Button } from 'components/Button/Button'
import { Form } from 'components/Form/Form'
import { FormItem } from 'components/FormItem/FormItem'
import { FormRow } from 'components/FormRow/FormRow'
import { Input } from 'components/Input/Input'
import { Modal } from 'components/Modal/Modal'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'
import { Switch } from 'components/Switch/Switch'
import { TextArea } from 'components/TextArea/TextArea'

import './NewHiringStageModal.scss'
import { sanitizeHtml } from 'utils/sanitizers.util'

const defaultRule = (): Yup.StringSchema => Yup.string().trim().required()
const emailAutosendRule = (rule: Yup.StringSchema) =>
  Yup.string().when('email_autosend', {
    is: true,
    then: rule
  })
const emailAutosendRuleForTextArea = () =>
  defaultRule().test('no-html-whitespace', 'Whitespace only', value => {
    if (value) {
      const strippedValue = sanitizeHtml(value)
      return strippedValue.trim() !== ''
    }
    return true
  })

const formInitialValues = {
  name: '',
  description: '',
  email_subject: '',
  email_autosend: false,
  email_content: ''
}

type NewHirginStageModalProps = {
  open: boolean
  order: number
  onSubmit: (payload: CreateNewHiringStatePayload) => Promise<void>
  closeModal: (value: boolean) => void
  isLoading?: boolean
}

export const NewHiringStageModal: React.FC<NewHirginStageModalProps> = ({
  open,
  order,
  onSubmit,
  closeModal,
  isLoading
}) => {
  const { t } = useTranslation('hiringWorkflow')

  const formik = useFormik<CreateNewHiringStatePayload>({
    initialValues: { ...formInitialValues, order },
    validationSchema: Yup.object({
      name: defaultRule(),
      description: defaultRule(),
      email_subject: emailAutosendRule(defaultRule()),
      email_content: emailAutosendRule(emailAutosendRuleForTextArea())
    }),
    onSubmit: async (values): Promise<void> => {
      values.order = order
      onSubmit(values)
      formik.handleReset(formik.initialValues)
    },
    validateOnMount: true
  })

  return (
    <div>
      <Modal
        onClose={() => {
          formik.handleReset(formik.initialValues)
          closeModal(!open)
        }}
        open={open}
        title={'+ ' + t('addNewStage')}
        closeIcon='cross'
      >
        <Form id='create-new-hiring-stage' onSubmit={formik.handleSubmit}>
          <div className='theme-lugarh'>
            <FormRow>
              <FormItem>
                <Input
                  name='name'
                  type='text'
                  label={t('stageName')}
                  small
                  value={formik.values.name}
                  onChange={(value: string) =>
                    formik.setFieldValue('name', value)
                  }
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className='error_class'>{t('error.name')}</div>
                ) : null}
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <TextArea
                  name='description'
                  label={t('stageDescription')}
                  small
                  value={formik.values.description}
                  onChange={(value: string) =>
                    formik.setFieldValue('description', value)
                  }
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className='error_class'>{t('error.description')}</div>
                ) : null}
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <Switch
                  label={t('sendEmailAutomatically')}
                  initialValue={formik.values.email_autosend}
                  onChange={(value: boolean) =>
                    formik.setFieldValue('email_autosend', value)
                  }
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <Input
                  name='email_subject'
                  type='text'
                  label={t('emailSubject')}
                  value={formik.values.email_subject}
                  onChange={(value: string) =>
                    formik.setFieldValue('email_subject', value)
                  }
                />
                {formik.errors.email_subject && formik.touched.email_subject ? (
                  <div>{formik.errors.email_subject}</div>
                ) : null}
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <RichTextEditor
                  placeholder={t('stageEmailContent')}
                  height={180}
                  value={formik.values.email_content}
                  onChange={(value: string) =>
                    formik.setFieldValue('email_content', value)
                  }
                />
                {formik.errors.email_content && formik.touched.email_content ? (
                  <div>{formik.errors.email_content}</div>
                ) : null}
              </FormItem>
            </FormRow>
          </div>
          <div className={'popup-box-actions medium void'}>
            <Button
              className={'popup-box-action full secondary'}
              disabled={isLoading || !formik.isValid}
            >
              {t('createHiringStage')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

import React, { useState } from 'react'

import { ChangeEnvironmentModal } from 'components/Modals/lugarh/ChangeEnvironment/ChangeEnvironmentModal'
import { useWorkspacePermission } from 'hooks/useWorkspacePermission'

import CandidateBackground from '../../../assets/images/background/candidates-background.png'

import './NoResultStructure.style.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {
  ThemeButton,
  ThemeButtonTemplate
} from 'components/Button/lugarh/ThemeButton'

import { useTranslation } from 'react-i18next'
interface NoResultStructureProps {
  title: string
  subtitle: string
  children?: React.ReactNode
}

export const NoResultStructure: React.FC<NoResultStructureProps> = ({
  title,
  subtitle,
  children
}) => {
  const { dontShowOnGeralWorkspace } = useWorkspacePermission()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { t } = useTranslation(`candidateHeader`)
  const onCloseModal = () => setOpenModal(false)

  return (
    <div className='theme-lugarh no-result__content'>
      <img src={CandidateBackground} alt='Candidate Background Image' />

      <div className='no-result__text'>
        <h1 className='txt-bold default-font txt-l'>{title}</h1>
        <p className='no-result__text--subtitle default-font txt-normal'>
          {dontShowOnGeralWorkspace
            ? subtitle
            : t(`lugarh.noResults.geralSubtitle`)}
        </p>
        <div className='no-result__buttons'>
          {dontShowOnGeralWorkspace ? (
            children
          ) : (
            <div className='theme-lugarh'>
              <ChangeEnvironmentModal
                open={openModal}
                onClose={onCloseModal}
                customClass='ws-modal'
              />
              <ThemeButton
                template={ThemeButtonTemplate.TEXT_ONLY}
                title={t(`lugarh.noResults.changeWorkspace`)}
                customClass='txt-s default-font no-result__buttons--changeWorkspace-button'
                onClick={() => setOpenModal(true)}
                endIcon={<ChevronRightIcon />}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
